import _ from 'lodash';
import {TransactionActionTypes} from "../actions/transactions";


const defaultState = {
  meta: {
    lastFetchAt: null,
    fetches: {
      // [stockId]: lastFetch
    }
  },
  records: {

  }
};

const getId = transaction => transaction.transactionId || transaction._id || transaction.id;

export default function Transactions(state = defaultState, action) {
  const records = _.cloneDeep(state.records);
  const meta = _.cloneDeep(state.meta);

  switch (action.type) {
    case TransactionActionTypes.PUT_TRANSACTION: {
      const { transaction } = action;
      records[getId(transaction)] = transaction;
      return {
        ...state,
        records
      };
    }
    case TransactionActionTypes.PUT_LAST_FETCH: {
      const { lastFetch } = action;
      meta.lastFetchAt = lastFetch;
      return {
        ...state,
        meta
      };
    }
    case TransactionActionTypes.PUT_TRANSACTIONS: {
      const { transactions } = action;
      transactions.forEach(stock => {
        records[getId(stock)] = stock
      });
      return {
        ...state,
        meta: {
          ...state.meta,
          lastFetchAt: new Date()
        },
        records
      };
    }
    case TransactionActionTypes.CLEAR_TRANSACTION: {
      const { transactionId } = action;
      delete records[transactionId];
      return {
        ...state,
        meta: {
          ...state.meta,
          lastFetchAt: null
        },
        records
      };
    }
    case TransactionActionTypes.CLEAR_TRANSACTIONS: {
      return {
        ...state,
        records: {}
      };
    }
    default: return state;
  }
}
