import Record from "../Record";
import {makeObservable} from "mobx";
import mapValues from "lodash/mapValues";

class Transaction extends Record {
  static INITIAL_FIELDS = {
    transactionId: '',
    userId: '',
    accountId: '',
    stockId: '',
    symbol: '',
    optionId:  '',
    callDate: '',
    deposit: null,
    fee: 0,
    amount: 0,
    quantity: 0,
    updatedAt: '',
    createdAt: ''
  };

  constructor(account, initialInfo) {
    super(initialInfo);
    makeObservable(this, {
      ...mapValues(this.constructor.INITIAL_FIELDS, () => true),
    });
    this.account = account;
  }


  destroy() {
    super.destroy();
  }
}

export default Transaction;
