import Record from "../Record";
import {makeObservable, runInAction} from "mobx";
import mapValues from "lodash/mapValues";
import makeQuery from "../../api/makeQuery";

class Option extends Record {
  static INITIAL_FIELDS = {
    id: '',
    stockId: '',
    resultId: '',
    pointId: '',
    symbol: '',
    cost: 0,
    price: 0,
    strike: 0,
    ask: 0,
    bid: 0,
    premium: 0,
    breakEvenValue: 0,
    actualValue: 0,
    minProfit: 0,
    maxProfit: 0,
    actualProfit: 0,
    annualProfitRate: 0,
    actualAnnualProfitRate: 0,
    probability: 0,
    volatility: 0,
    level: 0,
    grade: 0,
    callDate: '',
    date: '',
    time: '',
    updatedAt: '',
    createdAt: ''
  };

  constructor(account, initialInfo) {
    super(initialInfo);
    makeObservable(this, {
      ...mapValues(this.constructor.INITIAL_FIELDS, () => true),
    });
    this.account = account;
  }

  async subscribe() {
    if (this.isSubscribed) return;
    const accountInfo = await makeQuery('@postOptionSubscription', {
      userId: this.account?.userId,
      optionId: this.id,
      callDate: this.callDate
    });

    runInAction(() => {
      this.account?.merge(accountInfo);
    });
  }

  async unsubscribe() {
    if (!this.isSubscribed || !this.subscription) return;

    const accountInfo = await makeQuery('@deleteOptionSubscription', {
      userId: this.account?.userId,
      subscriptionId: this.subscription.id,
    });

    runInAction(() => {
      this.account?.merge(accountInfo);
    });
  }

  get isSubscribed() {
    return Boolean(this.account.subscribedOptions.getById(this.id));
  }

  get subscription() {
    return this.account?.optionSubs?.find(sub => sub.optionId === this.id);
  }

  destroy() {
    super.destroy();
  }
}

export default Option;
