import pick from 'util/pick';

const safeJsonParse = (obj, restrictedKeys) => {
  try {
    if (obj) {
      const parsed = JSON.parse(obj);
      return restrictedKeys ? pick(parsed, restrictedKeys) : parsed;
    }
    return null;
  } catch (e) {
    return null;
  }
};

export default safeJsonParse;
