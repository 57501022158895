import {runInAction} from 'mobx';
import CollectionBase from "models/CollectionBase";
import Stock from 'models/Stock';
import mergeMobxListsBy from "util/mergeMobxListsBy";

/*
  Sorting priority by scheduledAt

  true - false === 1;
  false - true === -1;
*/
// const createdAtAsc = (a, b) => (a.createdAt > b.createdAt ? -1 : 1);
// const createdAtDesc = (a, b) => (a.createdAt > b.createdAt ? 1 : -1);

class SubscribedStocksCollection extends CollectionBase {

  mergeStocks(stocks, shouldDelete = false) {
    runInAction(() =>
      mergeMobxListsBy(
        this.map,
        this.list,
        stocks,
        'stockId',
        (v) => new Stock(this.account, v),
        shouldDelete,
      ),
    );
  }

}

export default SubscribedStocksCollection;
