import {useMemo} from 'react';
import {useRouteMatch} from 'react-router';
import User from 'singletons/User';

export default (props) => {
  const { location, path, isPrivate } = props;

  const match = useRouteMatch(path);

  const userId = User.userId;
  const accessToken = User.auth.token;
  const isTokenExpired = User.auth.isExpired;
  const isTokenRefreshable = !User.auth.isRefreshExpired;
  const account = User.account;

  const isRelevantPath = useMemo(() => {
    if (!match) return false;
    return path === match.path;
  }, [location, path, match]);

  const shouldRedirectToLogin = useMemo(() => {
    if (!isRelevantPath) return false;
    return isPrivate && (!accessToken || (isTokenExpired && !isTokenRefreshable));
  }, [
    isPrivate,
    isRelevantPath,
    accessToken,
    isTokenExpired,
    isTokenRefreshable,
  ]);


  return {
    shouldRedirectToLogin,
    isRelevantPath,
    account,
    userId,
  };
};
