import PropTypes from 'prop-types';
import {Redirect, Route} from "react-router";
import paths from "./paths";
import useAuthRoute from "../hooks/useAuthRoute";

const AuthRoute = (props) => {
  const { account, userId, shouldRedirectToLogin } = useAuthRoute(props);

  if (shouldRedirectToLogin) {
    return (
      <Redirect
        to={{
          pathname: paths.auth.login,
          state: { referrer: props.path }
        }} />
    );
  }

  return (
    <Route
      {...props}
      account={account}
      userId={userId}
    />
  );
};

AuthRoute.propTypes = {
  token: PropTypes.string,
  userId: PropTypes.string,
  path: PropTypes.string,
};


export default AuthRoute;

