const TAG = 'API';

const SET_FETCHING = `${TAG}_setFetching`;
export const setFetching = (fetchType, isFetching) => ({ type: SET_FETCHING, isFetching, fetchType});

const SET_ERROR = `${TAG}_setError`;
export const setError = (fetchType, error) => ({ type: SET_ERROR, error, fetchType});

const CLEAR_ERROR = `${TAG}_clearError`;
export const clearError = (fetchType) => ({ type: CLEAR_ERROR, fetchType});

export const ApiActions = {
  SET_FETCHING,
  SET_ERROR,
  CLEAR_ERROR,
};
