import * as Yup from 'yup';
import {denormalizeJsonObject} from "../../helpers";

export const Schema = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string().required(),
  firstName: Yup.string().required(),
  lastName: Yup.string().required()
})

export const make = (params) => denormalizeJsonObject(params);

export const url = () => '/v1/auth/register';
