import _ from 'lodash';
import {SuggestionActionTypes} from "../actions/suggestions";


const defaultState = {
  meta: {
    fetches: {
      // [target.year.day]: lastFetchAt
    }
  },
  records: {

  }
};

const getId = result => result.suggestionId;

export default function Suggestions(state = defaultState, action) {
  const records = _.cloneDeep(state.records);

  switch (action.type) {
    case SuggestionActionTypes.PUT_LAST_FETCH: {
      const { fetchKey, lastFetch } = action;
      return {
        ...state,
        meta: {
          ...state.meta,
          fetches: {
            ...state.meta.fetches,
            [fetchKey]: lastFetch
          }
        }
      };
    }
    case SuggestionActionTypes.PUT_SUGGESTION: {
      const { suggestion } = action;
      records[getId(suggestion)] = suggestion;
      return {
        ...state,
        records
      };
    }
    case SuggestionActionTypes.PUT_SUGGESTIONS: {
      const { suggestions } = action;
      suggestions.forEach(suggestion => {
        records[getId(suggestion)] = suggestion
      });
      return {
        ...state,
        records
      };
    }
    case SuggestionActionTypes.CLEAR_SUGGESTION: {
      const { suggestionId } = action;
      delete records[suggestionId];
      return {
        ...state,
        records
      };
    }
    case SuggestionActionTypes.CLEAR_SUGGESTIONS: {
      return {
        ...state,
        records: {}
      };
    }
    default: return state;
  }
}
