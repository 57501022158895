import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {makeStyles} from '@material-ui/core/styles';
import containerStyle from "./containerStyle";
import * as AccountThunks from "../redux/thunks/accounts";
import * as ApiSelectors from "../redux/selectors/api";
import FetchTypes from "../util/fetch-types";
import ForgotPassword from "../pages/ForgotPassword";
import Loading from "../core/Loading";

const useStyles = makeStyles(theme => ({
  root: {
    ...containerStyle.root,
    width: '100%',
  }
}));

const ForgotPasswordContainer = props => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ForgotPassword {...props}/>
      { props.isApiFetching && <Loading noDrawer /> }
    </div>
  );
};

ForgotPasswordContainer.propTypes = {
  isApiFetching: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isApiFetching: ApiSelectors.isTypeFetching(FetchTypes.forgotPassword)(state),
});


const mapDispatchToProps = (dispatch) => ({
  forgotPassword: (email) => dispatch(
    AccountThunks.forgotPassword(email)
  )
});


export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordContainer);
