import { ToastContainer } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';
import 'css/toast-overrides.css';

const Notification = () => (
  <ToastContainer
    position='top-right'
    autoClose={4000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    draggable={false}
    pauseOnHover />
);

export default Notification;
