/* lodash pick works only with raw Objects */

/* @__PURE__ */
const pick = (obj, array) => {
  const result = {};
  if (obj && array) {
    for (const k of array) {
      if (k in obj) {
        result[k] = obj[k];
      }
    }
  }
  return result;
};

export default pick;
