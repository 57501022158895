import {createTheme} from "@material-ui/core";
import {hexToRgb} from "./core/react-helpers";

const theme = createTheme({
  palette: {
    primary: {
      // light: '#50FABE',
      // main: '#4CEEB4',
      main:'#37AD84'
      // mid: '#44D4A1',
      // dark: '#37AD84',
    },
    secondary: {
      main: '#31BDE0',
      // light: '#ED6A4C',
    },
    text: {
      primary: '#3e484c',
      secondary: `rgba(${hexToRgb('#3e484c')}, 0.5)`,
      grey: `rgba(${hexToRgb('#3e484c')}, 0.5)`,
      disabled: '#aeb3be',
      disabledLight: '#cee9ee',
      link: '#37AD84',
      primaryButton:{

      }
    },
    error: {
      main: '#A12E13',
    },
    background: {
      main: '#F3F9F6',
      dark: '#9EFFDD'
    },
    appBar: {
      // background: '#333333',
      background: '#21664E',
    },
    warn: {
      main: '#ED9D66',
    },
  },
  dimens: {
    spacing: 8,
    text: {
      normal: 16,
      caption: 12,
      title: 40,
    },
    drawerWidth: 300
  }
});

export default theme;
