const TAG = 'SUGGESTIONS';

const PUT_LAST_FETCH = `${TAG}/putLastFetch`;
export const putLastFetch = (fetchKey, lastFetch) => ({ type: PUT_LAST_FETCH, fetchKey, lastFetch });

// const PUT_LAST_TOP_FETCH = `${TAG}/putLastTopFetch`;
// export const putLastTopFetch = (fetchKey, lastFetch) => ({ type: PUT_LAST_TOP_FETCH, fetchKey, lastFetch });

const PUT_SUGGESTION = `${TAG}/putSuggestion`;
export const putSuggestion = (suggestion) => ({ type: PUT_SUGGESTION, suggestion });

const CLEAR_SUGGESTION = `${TAG}/clearSuggestion`;
export const clearSuggestion = (suggestionId) => ({ type: CLEAR_SUGGESTION, suggestionId });

const PUT_SUGGESTIONS = `${TAG}/putSuggestions`;
export const putSuggestions = (suggestions) => ({ type: PUT_SUGGESTIONS, suggestions });

const CLEAR_SUGGESTIONS = `${TAG}/clearSuggestions`;
export const clearSuggestions = () => ({ type: CLEAR_SUGGESTIONS });


export const SuggestionActionTypes = {
  PUT_LAST_FETCH,
  // PUT_LAST_TOP_FETCH,
  PUT_SUGGESTION,
  PUT_SUGGESTIONS,
  CLEAR_SUGGESTION,
  CLEAR_SUGGESTIONS
};
