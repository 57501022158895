import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';
import getObjectTransformer from 'util/getObjectTransformer';
import getDeepestData from 'util/getDeepestData';

import axios from 'axios';
import PendingQueries from 'singletons/PendingQueries';
import User from 'singletons/User';

import Requests from './requests';
import reportError from 'util/reportError';
import {ValidationError} from 'yup';
import buildConfig from './requests/build-config';

// eslint-disable-next-line prefer-regex-literals
const mongoIdRegex = new RegExp(/^[0-9a-fA-F]{24}$/);
const fromApiKeyTransform = (key) => mongoIdRegex.test(key) ? key : camelCase(key);
const fromApi = getObjectTransformer(fromApiKeyTransform);

const convertEmptyStringToNull = (v) => (v === '' ? null : v);

/*
  _id is for mongo;
*/
const toApiNormal = (empty = null) => getObjectTransformer(
  (k) => (k === 'id' ? '_id' : snakeCase(k)),
  convertEmptyStringToNull,
  empty
);

const toApiWithoutSnake = (empty = null) => getObjectTransformer(
  (k) => (k === 'id' ? '_id' : k),
  convertEmptyStringToNull,
  empty
);


async function makeQuery(fetchType, params) {
  const request = Requests[fetchType];

  if (!request) {
    throw new Error('No request for fetch type: ' + fetchType);
  }

  const {
    url: getUrl,
    method,
    make,
    Schema,
    doNotConvertKeysToSnakeCase,
    emptyObjectValue = null
  } = request;

  /*
    actions should not be called inside component observers.
    makeQuery is often used in pair with onBecomeObserved, which happens inside render.
    to avoid react errors, PendingQueries.add must be performed asynchronously
  */

  let queryId;

  try {
    await Schema.validate(params);
    queryId = PendingQueries.add(fetchType, params);
    const toApi = doNotConvertKeysToSnakeCase
      ? toApiWithoutSnake(emptyObjectValue)
      : toApiNormal(emptyObjectValue);
    const {token} = User.auth;
    const headers = {
      'Content-Type': 'application/json',
    };
    if (token) {
      headers['x-access-token'] = token;
    }
    const response = await axios({
      headers,
      mode: 'no-cors',
      params: buildConfig(params),
      method,
      baseURL: 'https://optionref.com/api',
      url: getUrl(params),
      data: toApi(await make(params)),
    }); // .catch(catchApiFailure);
    return fromApi(getDeepestData(response));
  } catch (e) {
    if (e instanceof ValidationError) {
      console.warn(
        fetchType + ' Query params: ',
        params,
        'Error path: ',
        e.path,
      );
      console.dir(e);
      reportError('PROMISE', `Error during query validation: ${fetchType}`);
    } else throw e;
  } finally {
    PendingQueries.remove(fetchType, queryId);
  }
}

export default makeQuery;
