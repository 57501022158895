import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  titleRoot: {
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(1),
  },
  titleRootWithCancel: {
    'paddingTop': theme.spacing(1),
    'paddingBottom': theme.spacing(1),
    '& > h2': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  xClose: {
    height: '1.4em',
    fontSize: '1.4rem',
    minWidth: 0,
  },
  noPaddingTop: {
    // overriding strange ui-components-web styling
    paddingTop: '0 !important',
  },
}));

export default useStyles;
