export default {
  login: 'login',
  getMe: 'getMe',
  maybeGetMe: 'maybeGetMe',
  logout: 'logout',
  reset: 'reset',
  register: 'register',
  forgotPassword: 'forgotPassword',
  getTransactions: 'getTransactions',
  addTransaction: 'addTransaction',
  addDepositTransaction: 'addDepositTransaction',
  deleteTransaction: 'deleteTransaction',
  getAccount: 'getAccount',
  maybeGetAccount: 'maybeGetAccount',
  getSubscribedStocks: "getSubscribedStocks",
  getSubscribedOptions: "getSubscribedOptions",
  getSuggestions: "getSuggestions",
  maybeGetStockOptions: "maybeGetStockOptions",
  getStockOptions: "getStockOptions",
  maybeGetSuggestions: "maybeGetSuggestions",
  getTopSuggestions: "getTopSuggestions",
  maybeGetTopSuggestions: "maybeGetTopSuggestions",
  maybeGetStocks: "maybeGetStocks",
  getStocks: "getStocks",
  maybeGetStock: "maybeGetStock",
  getStock: "getStock",
  maybeGetHistory: "maybeGetHistory",
  getStockHistory: "getStockHistory",
  addStockSub: "addStockSub",
  removeStockSub: "removeStockSub",
  addOptionSub: "addOptionSub",
  removeOptionSub: "removeOptionSub",
  updateStockSubNotify: "updateStockSubNotify",
  updateOptionSubNotify: "updateOptionSubNotify",
};
