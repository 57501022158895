import './App.css';
import {createBrowserHistory} from "history";
import {ThemeProvider} from "@material-ui/core/styles";
import {CssBaseline} from "@material-ui/core";
import { MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import theme from './theme';
import { store } from "./redux/store";
import {Provider} from "react-redux";
import MainNavigation from "./routes/MainNavigation";

const history = createBrowserHistory({});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Provider store={store}>
          <MainNavigation history={history}/>
        </Provider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

export default App;
