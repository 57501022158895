import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {makeStyles} from '@material-ui/core/styles';
import * as AccountThunks from '../redux/thunks/accounts';
import ResetPassword from "../pages/ResetPassword";
import Loading from "../core/Loading";
import * as ApiSelectors from "../redux/selectors/api";
import FetchTypes from "../util/fetch-types";
import containerStyle from "./containerStyle";

const useStyles = makeStyles(theme => ({
  root: {
    ...containerStyle.root,
    width: '100%',
  }
}));

const ResetPasswordContainer = props => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ResetPassword {...props}/>
      { props.isApiFetching && <Loading noDrawer /> }
    </div>
  );
};

ResetPasswordContainer.propTypes = {
  isApiFetching: PropTypes.bool
};

const mapStateToProps = (state) => ({
  isApiFetching: ApiSelectors.isTypeFetching(FetchTypes.reset)(state),
});


const mapDispatchToProps = (dispatch) => ({
  resetPassword: (password, resetToken) => dispatch(
    AccountThunks.resetPassword(password, resetToken)
  ),
});


export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordContainer);
