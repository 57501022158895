import {runInAction} from 'mobx';
import CollectionBase from "models/CollectionBase";
import Option from 'models/Option';
import mergeMobxListsBy from "util/mergeMobxListsBy";

/*
  Sorting priority by scheduledAt

  true - false === 1;
  false - true === -1;
*/
// const createdAtAsc = (a, b) => (a.createdAt > b.createdAt ? -1 : 1);
// const createdAtDesc = (a, b) => (a.createdAt > b.createdAt ? 1 : -1);

class SubscribedOptionsCollection extends CollectionBase {
  mergeOptions(options, shouldDelete = false) {
    runInAction(() =>
      mergeMobxListsBy(
        this.map,
        this.list,
        options,
        'optionId',
        (v) => new Option(this.account, v),
        shouldDelete,
      ),
    );
  }

}

export default SubscribedOptionsCollection;
