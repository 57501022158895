import * as Yup from 'yup';
import {denormalizeJsonObject} from "../../helpers";

export const Schema = Yup.object().shape({
  resetToken: Yup.string().required(),
  password: Yup.string().required()
})


export const make = (params) => denormalizeJsonObject(params);

export const url = () => '/v1/auth/reset';
