import PropTypes from 'prop-types';
import Root from './Root';
import Notification from '../components/notification/Notification';
import ModalsContainer from '../components/ModalsContainer';

const MainNavigation = ({ history }) => {
  return (
    <div>
      <Root history={history} />
      <ModalsContainer />
      <Notification />
    </div>
  );
};

MainNavigation.propTypes = {
  history: PropTypes.any.isRequired,
};

export default MainNavigation;
