import {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Field, Formik} from "formik";
import Card from "../components/Card";
import {TextField} from "formik-material-ui";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import pageStyle from "./pageStyle";
import {Link} from "react-router-dom";
import paths from "../routes/paths";
import User from "../singletons/User";
import {useHistory} from "react-router";

const useStyles = makeStyles(theme => ({
  root: {
    ...pageStyle.root
  },
  passwordIcon: {
    color: '#999999'
  },
  card: {
    margin: `${theme.spacing(4)}px 0`,
    width: '100%',
    maxWidth: 400,
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    // marginTop: -theme.spacing(8),
    // color: theme.palette.text.secondary,
  },
  input: {
    marginTop: theme.spacing(2),
  },
  forgot: {
    margin: `0 ${theme.spacing()}px`
  },
  spacer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  },
  loginBtn: {
    color: 'white',
  },
  actions: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  register: {
    marginTop: `${theme.spacing(2)}px`,
    textAlign: 'center',
    alignItems: 'center'
  }
}));

const Login = props => {
  const classes = useStyles();
  const history = useHistory();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const handleMouseVisibility = event => event.preventDefault();
  const togglePasswordVisibility = () => setPasswordVisible(!passwordVisible);

  const attemptLogin = async (data, { resetForm }) => {
    await User.login(data.email, data.password)
    resetForm(data);
  };

  const goToForgotPassword = () => history.push(paths.auth.forgotPassword);

  return (
    <div className={classes.root}>
      <div className={classes.spacer}/>
      <Card className={classes.card}>
        <div>
          <Typography className={classes.title} color={'primary'} variant={'h4'}>
            OptionRef
          </Typography>
          <Typography variant={'body1'}>
            Please log in below
          </Typography>
        </div>
        <Formik
          initialValues={{ email: '', password: '' }}
          onSubmit={attemptLogin}>
          {
            (props) => {
              // eslint-disable-next-line react/prop-types
              const {values, handleSubmit} = props;
              // eslint-disable-next-line react/prop-types
              const { email, password } = values;
              return (
                <div className={classes.form}>
                  <form onSubmit={handleSubmit}>
                    <div>
                      <Field
                        fullWidth
                        className={classes.input}
                        name={'email'}
                        value={email}
                        component={TextField}
                        InputProps={{ fullWidth: true }} />
                    </div>
                    <div>
                      <Field
                        className={classes.input}
                        fullWidth
                        name={'password'}
                        type={passwordVisible ? 'text' : 'password'}
                        value={password}
                        InputProps={{
                          fullWidth: true,
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onMouseDown={handleMouseVisibility}
                                onClick={togglePasswordVisibility}>
                                {
                                  passwordVisible
                                    ? <VisibilityOff className={classes.passwordIcon} />
                                    : <Visibility color={'primary'} className={classes.passwordIcon} />
                                }
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        component={TextField} />
                      <div className={classes.actions}>
                        <Button
                          onClick={goToForgotPassword}
                          className={classes.forgot}
                          variant={'text'}
                          color={'textSecondary'}>
                          Forgot Password
                        </Button>
                        <Button
                          type={'submit'}
                          className={classes.loginBtn}
                          variant={'contained'}
                          color={'primary'}>
                          Login
                        </Button>
                      </div>
                      <div className={classes.register}>
                        <Link to={paths.auth.register}>Create an Account</Link>
                      </div>
                    </div>
                  </form>
                </div>
              )
            }
          }
        </Formik>
      </Card>
      <div className={classes.spacer} />
    </div>
  );
};

Login.propTypes = {

};

export default Login;
