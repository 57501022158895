import {ViewActionTypes} from "../actions/view";

const defaultState = {
  login: {
    userId: undefined,
    user: {
      userId: undefined,
      firstName: undefined,
      lastName: undefined,
      email: undefined,
    },
    token: undefined,
    refreshToken: undefined,
  },
  dialogs: {
    // [deviceId]: {
    // }
  },
  alert: {
    isAlert: false,
    body: '',
    title: '',
    success: false,
  },
  errors: [],
  fieldError: {
    // [field name/id]: error
  },
  window: {
    height: 0,
    width: 0
  }
};

export default function View(state = defaultState, action) {
  switch (action.type) {
    case ViewActionTypes.SET_USER_ID: {
      return {
        ...state,
        login: {
          ...state.login,
          userId: action.userId,
        }
      }
    }
    case ViewActionTypes.SET_USER: {
      const { firstName, lastName, email, userId } = action.user;
      return {
        ...state,
        login: {
          ...state.login,
          user: {
            ...state.login.user,
            firstName,
            lastName,
            email,
            userId,
          }
        }
      }
    }
    case ViewActionTypes.SET_AUTH_TOKEN: {
      return {
        ...state,
        login: {
          ...state.login,
          token: action.token,
        }
      }
    }
    case ViewActionTypes.SET_REFRESH_TOKEN: {
      return {
        ...state,
        login: {
          ...state.login,
          refreshToken: action.refreshToken,
        }
      }
    }
    case ViewActionTypes.SET_ALERT_VISIBLE: {
      return {
        ...state,
        alert: {
          ...state.alert,
          isAlert: action.visible,
        }
      }
    }
    case ViewActionTypes.PUT_ALERT: {
      return {
        ...state,
        alert: {
          isAlert: !!(action.title || action.body),
          title: action.title,
          body: action.body,
          type: action.alertType,
        }
      }
    }
    case ViewActionTypes.UPDATE_WINDOW_SIZE: {
      return {
        ...state,
        window: {
          ...state.window,
          height: action.height,
          width: action.width,
        }
      }
    }
    case ViewActionTypes.PUT_ERROR: {
      return {
        ...state,
        errors: [...state.errors, action.error]
      }
    }
    case ViewActionTypes.PUT_FIELD_ERROR: {
      return {
        ...state,
        fieldError:{
          ...state.fieldError,
          [action.field]: action.error
        }
      }
    }
    case ViewActionTypes.CLEAR_ERRORS: {
      return {
        ...state,
        errors: defaultState.errors,
        fieldError: defaultState.fieldError
      }
    }
    default: return state;
  }
}
