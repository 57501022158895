import * as Yup from 'yup';

export const Schema = Yup.object().shape({
  stockId: Yup.string()
    .test(function (value) {
      const { symbol } = this.parent;
      if (!symbol) return value != null;
      return true;
    }),
  symbol: Yup.string()
    .test(function (value) {
      const { stockId } = this.parent;
      if (!stockId) return value != null;
      return true;
    }),
});

export const url = ({stockId, symbol}) => {
  if (stockId) {
    return `/v1/stocks/${stockId}`;
  } else {
    return `/v1/stocks/symbol/${symbol}`;
  }
}
