import PropTypes from 'prop-types';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from '@material-ui/icons/Menu';
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";

import logo from '../logo.svg';
import Spacer from "../core/Spacer";
import {Hidden} from "@material-ui/core";
import {Link} from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {},
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    textAlign: 'start',
    flexGrow: 1,
    color: theme.palette.primary.main
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#333333',
    color: 'white'
  }
}));

const NavBar = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppBar position={"fixed"} className={classes.appBar}>
        <Toolbar>
          {
            !!props.menu && (
              <Hidden mdUp>
                <IconButton
                  onClick={props.onMenuClick}
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="menu">
                  <MenuIcon/>
                </IconButton>
              </Hidden>
            )
          }
          <a href={'/'}>
            <img src={logo} className={classes.logo} alt={'OptionRef'}/>
          </a>
          {
            !!props.title && (
              <Link to={'/'}>
                <Typography variant="h6" className={classes.title}>
                  {props.title}
                </Typography>
              </Link>
            )
          }
          <Spacer/>
          {props.children}
        </Toolbar>
      </AppBar>
    </div>
  );
};

NavBar.propTypes = {
  menu: PropTypes.bool,
  title: PropTypes.string,
  onMenuClick: PropTypes.func,
  children: PropTypes.node,
};


export default NavBar;

