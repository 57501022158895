import FetchTypes from "../../util/fetch-types";
import * as LoginRequest from './v1/post-login';
import * as MeRequest from './v1/get-me';
import * as RegistrationRequest from './v1/post-register-account';
import * as ResetPasswordRequest from './v1/post-reset-password';
import * as ForgotPasswordRequest from './v1/post-forgot-password';
import * as GetAccountRequest from './v1/get-account';
import * as GetSuggestionsRequest from './v1/get-options';
import * as GetTopSuggestionsRequest from './v1/get-top-options';
import * as GetStocksRequest from './v1/get-stocks';
import * as GetStockRequest from './v1/get-stock';
import * as GetStockOptionsRequest from './v1/get-stock-options';
import * as GetAccountTransactions from './v1/get-account-transactions';
import * as PostOptionTransaction from './v1/post-option-transaction';
import * as PostDepositTransaction from './v1/post-deposit-transaction';
import * as DeleteOptionTransaction from './v1/delete-option-transaction';
import * as PostOptionSubscription from './v1/post-option-subscription';
import * as PostStockSubscription from './v1/post-stock-subscription';
import * as PutStockSubscriptionNotify from './v1/put-stock-subscription-notify';
import * as PutOptionSubscriptionNotify from './v1/put-option-subscription-notify';
import * as DeleteStockSubscription from './v1/delete-stock-subscription';
import * as DeleteOptionSubscription from './v1/delete-option-subscription';

export default {
  getRequest: (fetchType) => {
    if (!fetchType) return null;
    switch (fetchType) {
      case FetchTypes.login: return LoginRequest;
      case FetchTypes.getMe: return MeRequest;
      case FetchTypes.register: return RegistrationRequest;
      case FetchTypes.reset: return ResetPasswordRequest;
      case FetchTypes.forgotPassword: return ForgotPasswordRequest;
      case FetchTypes.getAccount: return GetAccountRequest;
      case FetchTypes.getSuggestions: return GetSuggestionsRequest;
      case FetchTypes.getTopSuggestions: return GetTopSuggestionsRequest;
      case FetchTypes.getStock: return GetStockRequest;
      case FetchTypes.getStocks: return GetStocksRequest;
      case FetchTypes.getStockOptions: return GetStockOptionsRequest;
      case FetchTypes.getTransactions: return GetAccountTransactions;
      case FetchTypes.addTransaction: return PostOptionTransaction;
      case FetchTypes.addDepositTransaction: return PostDepositTransaction;
      case FetchTypes.deleteTransaction: return DeleteOptionTransaction;
      case FetchTypes.addOptionSub: return PostOptionSubscription;
      case FetchTypes.addStockSub: return PostStockSubscription;
      case FetchTypes.updateOptionSubNotify: return PutOptionSubscriptionNotify;
      case FetchTypes.updateStockSubNotify: return PutStockSubscriptionNotify;
      case FetchTypes.removeStockSub: return DeleteStockSubscription;
      case FetchTypes.removeOptionSub: return DeleteOptionSubscription;
    }
  },
}
