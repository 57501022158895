import _ from 'lodash';
import {OptionActionTypes} from "../actions/options";
import {buildOptionsPageKey, buildStockOptionsPageKey} from "util/options";


const defaultState = {
  meta: {
    counts: {
      // [target.date]: count
    }
  },
  records: {
    // [target.date.page.limit.sortBy.direction] = [ ...options ]
  },
  stocks: {
    // [stockId.page.limit.sortBy.order.filters...] = [ ...options ]
  },
  top: {
    // [date::balance] = { [lowBound::highBound]: [...options] }
  }
};

const getId = option => option._id || option.id;

const isNumberSame = (val1, val2) => Number(val1) === Number(val2);

export default function Options(state = defaultState, action) {
  const records = _.cloneDeep(state.records);
  const stocks = _.cloneDeep(state.stocks);
  const top = _.cloneDeep(state.top);
  // console.log(action);

  switch (action.type) {
    case OptionActionTypes.PUT_OPTION: {
      const { option } = action;
      records[getId(option)] = option;
      return {
        ...state,
        records
      };
    }
    case OptionActionTypes.PUT_SUBSCRIBED_OPTIONS: {
      const { options = [] } = action;
      options.forEach((option) => {
        records[getId(option)] = option
      });
      return {
        ...state,
        records
      };
    }
    case OptionActionTypes.PUT_TOP_OPTIONS: {
      const { topOptions, date, balance } = action;
      top[`${date}::${balance}`] = topOptions.reduce(
        (acc, range) => {
          if (range.upperBoundary) {
            acc[`${range.lowerBoundary}::${range.upperBoundary}`] = range.options;
          } else if (range.lowerBoundary !== 'other') {
            acc[`${range.lowerBoundary}::other`] = range.options;
          }
          return acc;
        }, {}
      );
      // console.log({ day, year, balance });
      return {
        ...state,
        top
      }
    }
    case OptionActionTypes.PUT_OPTIONS: {
      const { options, params, meta } = action;
      const { date, target, page = 1, limit = 10, sort = { probability: -1 }, filter = {} } = params;
      const rKey = buildOptionsPageKey(date, target, {filter, page, limit, sort});
      const [sortBy] = Object.keys(sort);
      const outdatedKeys = Object.keys(records).filter(key => {
        const [trgt, dte, pge, limt, srtBy, order, ...fltrs] = key.split('.');
        const filters = fltrs.reduce(
          (acc, curr, i) => {
            if (i % 2 === 0) {
              // this is a key
              acc[curr] = curr;
            } else {
              // this is a value for the last index
              acc[ fltrs[i - 1] ] = curr;
            }
            return acc;
          }, {}
        );
        const isSameDay = trgt === target && dte === date;
        return isSameDay && (
          Number(pge) === page
            || !isNumberSame(limit, limt)
            || sortBy !== srtBy
            || !isNumberSame(sort[sortBy], order)
            || !_.isEqual(filters, filter)
        );
      });

      outdatedKeys.forEach((key) => {
        delete records[key];
      });

      records[rKey] = options;

      return {
        ...state,
        meta: {
          ...state.meta,
          counts: {
            ...state.meta.counts,
            [rKey]: meta.count
          }
        },
        records
      };
    }
    case OptionActionTypes.PUT_STOCK_OPTIONS: {
      const { options, params, meta } = action;
      const { stockId, page = 1, limit = 10, sort = { probability: -1 }, filter = {} } = params;
      const rKey = buildStockOptionsPageKey(stockId, {filter, page, limit, sort});
      const [sortBy] = Object.keys(sort);
      const outdatedKeys = Object.keys(stocks).filter(key => {
        const [_stockId, pge, limt, srtBy, order, ...fltrs] = key.split('.');
        const filters = fltrs.reduce(
          (acc, curr, i) => {
            if (i % 2 === 0) {
              // this is a key
              acc[curr] = curr;
            } else {
              // this is a value for the last index
              acc[ fltrs[i - 1] ] = curr;
            }
            return acc;
          }, {}
        );
        return stockId === _stockId && (
          Number(pge) === page
            || !isNumberSame(limit, limt)
            || sortBy !== srtBy
            || !isNumberSame(sort[sortBy], order)
            || !_.isEqual(filters, filter)
        );
      });

      outdatedKeys.forEach((key) => {
        delete records[key];
      });

      stocks[rKey] = options;

      return {
        ...state,
        meta: {
          ...state.meta,
          counts: {
            ...state.meta.counts,
            [rKey]: meta.count
          }
        },
        stocks
      };
    }
    case OptionActionTypes.CLEAR_OPTION: {
      const { optionId } = action;
      delete records[optionId];
      return {
        ...state,
        records
      };
    }
    case OptionActionTypes.CLEAR_OPTIONS: {
      return {
        ...state,
        records: {}
      };
    }
    default: return state;
  }
}
