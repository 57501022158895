import {makeObservable} from 'mobx';
import groupBy from 'lodash/groupBy';

/*
  Sorting priority by scheduledAt

  true - false === 1;
  false - true === -1;
*/
// const createdAtAsc = (a, b) => (a.createdAt > b.createdAt ? -1 : 1);
// const createdAtDesc = (a, b) => (a.createdAt > b.createdAt ? 1 : -1);

class CollectionBase {
  list = [];
  map = new Map();

  constructor(account) {
    makeObservable(this, {
      list: true,
      map: true,
      length: true,

      bySymbol: true,
      byStockId: true,
    });
    this.account = account;
  }

  destroy() {
    for (const item of this.list) {
      item.destroy();
    }
  }

  getById(id) {
    return this.map.get(id);
  }

  getBySymbol(symbol) {
    return this.bySymbol[symbol]
  }

  getByStockId(stockId) {
    return this.byStockId[stockId]
  }


  get length() {
    return this.list.length;
  }

  get bySymbol() {
    return groupBy(this.list, 'symbol');
  }

  get byStockId() {
    return groupBy(this.list, 'stockId');
  }
}

export default CollectionBase;
