import {Router, Switch} from "react-router-dom";
import PropTypes from "prop-types";
import routeTree from './index'
import RouteBranch from "./RouteBranch";
import GlobalEvents from "singletons/GlobalEvents";
import {useEffect, Suspense} from "react";
import {toast} from "material-react-toastify";
import Loading from "core/Loading";
import ScrollToTop from "core/ScrollToTop";
import ErrorCatcher from "core/ErrorCatcher";

const SwitchRoot = (props) => {
  useEffect(
    () =>
      GlobalEvents.listen((type, data) => {
        switch (type) {
          case 'unknownError':
            toast.error(data.printedError, {toastId: data.printedError});
            break;
          case 'forgotPassword':
            data.status === 'success'
              ? toast.success(
                `Reset password email has been sent to ${data.email}`,
              )
              : toast.error(
                `Error sending reset password email to ${data.email}`,
              );
            break;
          case 'cancelPendingVisit':
            if (data.status === 'success') {
              toast.success(
                `Your visit with ${data.providerName} was cancelled.`,
              );
            }
            break;
          case 'updateUserPassword':
            if (data.status === 'success') {
              toast.success('Password was successfully updated');
            }
            break;
          case 'updateMember':
            data.status === 'success'
              ? toast.success('Your account updated successfully')
              : toast.error('Failed to update your account');
            break;
        }
      }),
    [],
  );
  return (
    <Suspense fallback={<Loading loading={true} />}>
      <Switch>
        {routeTree.map(({path, ...route}) => (
          <RouteBranch key={path} path={path} {...props} {...route} />
        ))}
      </Switch>
    </Suspense>
  );
};

const Root = ({ history }) => {

  return (
    <Router basename={"/"} history={history}>
      <ErrorCatcher>
        <ScrollToTop />
        <SwitchRoot />
      </ErrorCatcher>
    </Router>
  );
};

Root.propTypes = {
  history: PropTypes.object,
};

export default Root;
