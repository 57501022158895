import {makeObservable, action} from 'mobx';
import difference from 'lodash/difference';
import pick from 'util/pick';

const LOG_KEYS_DIFF = false;
class Record {
  static INITIAL_FIELDS = {};

  initialize(values) {
    Object.assign(
      this,
      this.constructor.INITIAL_FIELDS,
      pick(values, this.ALLOWED_FIELDS),
    );
    this.initializeTimestamp = Date.now();
  }

  destroy() {}

  merge(values) {
    if (values) {
      const allowedValues = pick(values, this.ALLOWED_FIELDS);
      if (LOG_KEYS_DIFF) {
        const keysDiff = difference(
          Object.keys(values),
          Object.keys(allowedValues),
        );
        if (keysDiff.length) {
          console.log(
            'Unexpected keys in merge:',
            this.constructor.name,
            keysDiff,
            this,
          );
        }
      }
      Object.assign(this, allowedValues);
      this.initializeTimestamp = Date.now();
    }
  }

  constructor(values) {
    this.ALLOWED_FIELDS = Object.keys(this.constructor.INITIAL_FIELDS);
    this.initializeTimestamp = 0;
    this.initialize(values);
    makeObservable(this, {
      initialize: action,
      merge: action,
    });
  }
}

export default Record;
