import PropTypes from 'prop-types';
import {ThemeProvider} from '@material-ui/core/styles';
import overrideTheme from 'theming/overrides/modal';
import useStyles from './styles';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";

const Modal = ({
                 children,
                 open,
                 title,
                 onClose,
                 titleCancelButtonVariant,
                 dialogClasses,
               }) => {
  const classes = useStyles();
  const isTitleEnabled = title || titleCancelButtonVariant;
  return (
    <ThemeProvider theme={overrideTheme}>
      <Dialog open={open} onClose={onClose} classes={dialogClasses}>
        {isTitleEnabled && (
          <DialogTitle
            classes={{
              root: titleCancelButtonVariant
                ? classes.titleRootWithCancel
                : classes.titleRoot,
            }}
          >
            <Typography variant={'body1'}>{title}</Typography>
            {titleCancelButtonVariant === 'X' ? (
              <Button className={classes.xClose} onClick={onClose}>
                &times;
              </Button>
            ) : titleCancelButtonVariant ? (
              <Button color={'primary'} onClick={onClose}>
                Cancel
              </Button>
            ) : null}
          </DialogTitle>
        )}
        <DialogContent className={classes.noPaddingTop} dividers>
          {children}
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
};
Modal.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  titleCancelButtonVariant: PropTypes.any.isRequired,
  dialogClasses: PropTypes.object,
};
Modal.defaultProps = {
  children: null,
  dialogClasses: {},
  title: null,
};
export default Modal;
