import moment from "moment-timezone";

export const acceptableUpdateDate = () => {
  const now = moment().tz("America/Chicago");
  const day = now.day();
  const hour = now.hour();

  let acceptableDate;
  if (day === 6 || day === 0 || day === 1 && hour <= 14) {
    // it's the weekend!
    acceptableDate = moment().tz("America/Chicago").subtract(6, 'days').day(5).hour(14).minute(58).second(30).toDate();
  } else {
    if (hour > 14) {
      // It's a weekday after the market closes
      acceptableDate = moment().tz("America/Chicago").hour(14).minute(55).second(30).toDate();
    } else {
      // It's a weekday before the market closes
      acceptableDate = moment().tz("America/Chicago").day(day - 1).hour(14).minute(55).second(30).toDate();
    }
  }

  return acceptableDate;
};

export const getCurrentOptionsDate = () => {
  const m = moment();
  const d = m.day();
  if (d === 6) {
    m.day(5);
  } else if (d === 0) {
    m.subtract(2, 'days');
  } else if (m.hour() < 15 || (m.hour() === 15 && m.minute() < 30)) {
    if (d === 1) {
      m.subtract(3, 'days');
    } else {
      m.day(d - 1);
    }
  }

  return m.format('YYYY-MM-DD');
}
