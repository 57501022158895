import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import AuthLayout from "../layouts/AuthLayout";
import RouteBranch from "./RouteBranch";
import routeStyle from "./routeStyle";

const useStyles = makeStyles(theme => ({
  root: {
    ...routeStyle(theme)
  }
}));

const Auth = ({routes,...props}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AuthLayout>
        {
          routes.map(
            ({ path, ...route}) => {
              return <RouteBranch key={path} path={path} {...props} {...route} />
            }
          )
        }
      </AuthLayout>
    </div>
  );
};

Auth.propTypes = {
  routes: PropTypes.array,
};

export default Auth;
