const TAG = 'OPTIONS';

const PUT_OPTION = `${TAG}/putOption`;
export const putOption = (option) => ({ type: PUT_OPTION, option });

const CLEAR_OPTION = `${TAG}/clearOption`;
export const clearOption = (optionId) => ({ type: CLEAR_OPTION, optionId });

const PUT_OPTIONS = `${TAG}/putOptions`;
export const putOptions = (options, params, meta) => ({ type: PUT_OPTIONS, options, params, meta });

const PUT_TOP_OPTIONS = `${TAG}/putTopOptions`;
export const putTopOptions = (topOptions, date, balance) => ({ type: PUT_TOP_OPTIONS, topOptions, date, balance });

const PUT_SUBSCRIBED_OPTIONS = `${TAG}/putSubscribedOptions`;
export const putSubscribedOptions = (options) => ({ type: PUT_SUBSCRIBED_OPTIONS, options });

const PUT_STOCK_OPTIONS = `${TAG}/putStockOptions`;
export const putStockOptions = (options, params, meta) => ({ type: PUT_STOCK_OPTIONS, options, params, meta });

const CLEAR_OPTIONS = `${TAG}/clearOptions`;
export const clearOptions = () => ({ type: CLEAR_OPTIONS });


export const OptionActionTypes = {
  PUT_OPTION,
  PUT_OPTIONS,
  PUT_TOP_OPTIONS,
  PUT_STOCK_OPTIONS,
  PUT_SUBSCRIBED_OPTIONS,
  CLEAR_OPTION,
  CLEAR_OPTIONS
};
