export default {
  root: {
    flex: 1,
    display: 'flex'
  },
  content: {
    flex: 1,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
  }
};
