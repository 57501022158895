import * as Yup from 'yup';

export const Schema = Yup.object().shape({
  stockId: Yup.string().required(),
  params: Yup.object()
})

export const url = ({ stockId }) => {
  return `/v1/options?stock_id=${stockId}`;
};
