import {
  makeObservable,
  onBecomeObserved,
  action,
  runInAction,
} from 'mobx';
import mapValues from 'lodash/mapValues';
import debounce from 'lodash/debounce';
import makeQuery from 'api/makeQuery';
import GlobalEvents from 'singletons/GlobalEvents';
import Record from "models/Record";
import TransactionsCollection from "./TransactionsCollection";
import SubscribedOptionsCollection from "./SubscribedOptionsCollection";
import SubscribedStocksCollection from "./SubscribedStocksCollection";
import SearchOptionsCollection from "./SearchOptionsCollection";

class Account extends Record {
  static INITIAL_FIELDS = {
    accountId: '',
    userId: '',
    balance: null,
    notifications: [],
    pendingOptions: [],
    optionSubs: [],
    stockSubs: []
  };

  transactions = new TransactionsCollection(this);

  subscribedOptions = new SubscribedOptionsCollection(this);

  subscribedStocks = new SubscribedStocksCollection(this);

  searchedOptions = new SearchOptionsCollection(this);

  constructor(initialInfo) {
    super(initialInfo);
    makeObservable(this, {
      ...mapValues(this.constructor.INITIAL_FIELDS, () => true),
      refetchMe: action,
    });

    this.disposeSubbedOptionsFetcher = onBecomeObserved(
      this.subscribedOptions,
      'list',
      () => {
        this.refetchMe();
      },
    );

    this.disposeSubbedStocksFetcher = onBecomeObserved(
      this.subscribedStocks,
      'list',
      () => {
        this.refetchMe();
      },
    );

    this.disposeTransactionsFetcher = onBecomeObserved(
      this.transactions,
      'list',
      () => {
        this.transactions.refetch();
      },
    );

    this.disposeSearchOptionsFetcher = onBecomeObserved(
      this.searchedOptions,
      'list',
      () => {
        this.searchedOptions.refetch();
      },
    );

  }

  refetchMe = debounce(async (emitEvent) => {
    const { stocks, options, transactions, account } = await makeQuery('@getMe');
    runInAction(() => {
      this.merge(account);
      this.subscribedOptions.mergeOptions(options, true);
      this.subscribedStocks.mergeStocks(stocks, true);
      this.transactions.replace(transactions);
    })
    if (emitEvent) {
      GlobalEvents.emit('refetchMe');
    }
  }, 500);

  async purchaseOption({
     optionId,
     quantity,
     fee = 10,
   }) {
    const option = this.searchedOptions.getById(optionId) ?? this.subscribedOptions?.getById(optionId);
    await makeQuery('@postOptionTransaction', {
      accountId: this.accountId,
      transaction: {
        userId: this.userId,
        accountId: this.accountId,
        amount: (option.bid * quantity) - (option.premium * quantity),
        stockId: option.stockId,
        optionId,
        callDate: option.callDate,
        deposit: false,
        fee,
        quantity,
        symbol: option.symbol,
      }
    });
    await this.transactions.refetch();
  }


  destroy() {
    this.disposeSubbedStocksFetcher();
    this.disposeSubbedOptionsFetcher();
    this.disposeTransactionsFetcher();
    this.disposeSearchOptionsFetcher();
    this.subscribedOptions.destroy();
    this.subscribedStocks.destroy();
    this.transactions.destroy();
    this.searchedOptions.destroy();
    super.destroy();
  }
}
export default Account;
