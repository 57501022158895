import {makeObservable, action, observable} from 'mobx';
import Account from 'models/Account';
import makeQuery from 'api/makeQuery';
import throttle from 'lodash/throttle';

class Accounts {
  byId = new Map();
  fetchQueue = new Set();

  processQueue = throttle(async () => {
    if (!this.fetchQueue.size) {
      return;
    }

    const ids = Array.from(this.fetchQueue);

    for (const userId of ids) {
      if (userId) {
        const accountInfo = await makeQuery('@getAccount', {
          userId: userId,
        });
        if (process.env.NODE_ENV !== 'production') {
          console.log('Fetched account: ', accountInfo);
        }
        this.upsert(userId, accountInfo);
      }
      this.fetchQueue.delete(userId);
    }

    if (this.fetchQueue.size) {
      this.processQueue();
    }
  }, 500);

  constructor() {
    makeObservable(this, {
      upsert: action,
    });
  }

  get(userId) {
    let accountBox = this.byId.get(userId);
    console.log({accountBox});
    if (!accountBox) {
      accountBox = observable.box();
      this.byId.set(userId, accountBox);
      this.fetchQueue.add(userId);
      this.processQueue();
    }

    return accountBox.get();
  }

  clear() {
    for (const account of this.byId.values()) {
      account.get()?.destroy();
    }
    this.byId.clear();
  }

  upsert(userId, accountInfo) {
    let accountBox = this.byId.get(userId);

    if (!accountBox) {
      accountBox = observable.box();
      this.byId.set(userId, accountBox);
    }

    let member = accountBox.get();

    if (member) {
      member.initialize(accountInfo);
    } else {
      member = new Account(accountInfo);
      accountBox.set(member);
    }
  }
}

export default new Accounts();
