import _ from 'lodash';
import * as Yup from 'yup';
import {denormalizeJsonObject} from "../../helpers";

export const Schema = Yup.object().shape({
  accountId: Yup.string().min(12, "Too Short").required('Required'),
  transaction: Yup.object().shape({
    id: Yup.string(),
    transactionId: Yup.string(),
    userId: Yup.string().required(),
    accountId: Yup.string().required(),
    amount: Yup.number().required(),
    stockId: Yup.string()
      .min(12, "Too Short")
      .required('Required'),
    optionId: Yup.string()
      .min(12, "Too Short")
      .required('Required'),
    callDate: Yup.string()
      .matches(/^(\d{4}-\d{2}-\d{2})$/)
      .required('Required'),
    deposit: Yup.boolean(),
    fee: Yup.number(),
    quantity: Yup.number().required(),
    symbol: Yup.string().min(1, "Too Short").required('Required'),
  })
});

export const make = (params) => {
  const transaction = _.cloneDeep(params.transaction);
  if (transaction.transactionId) {
    transaction._id = transaction.transactionId;
    delete transaction.transactionId;
  }
  return JSON.stringify(denormalizeJsonObject({ transaction }));
};

export const url = ({ accountId }) => `/v1/accounts/${accountId}/transactions`;
