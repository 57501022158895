import {useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {Field, Formik} from "formik";
import {TextField} from "formik-material-ui";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import * as Yup from 'yup';
import Card from "../components/Card";
import pageStyle from "./pageStyle";
import paths from "../routes/paths";
import {useHistory} from "react-router";

const useStyles = makeStyles(theme => ({
  root: {
    ...pageStyle.root,
    padding: `0 ${theme.spacing(2)}px`,
  },
  passwordIcon: {
    color: '#999999'
  },
  card: {
    margin: `${theme.spacing(4)}px ${theme.spacing(2)}px`,
    width: '100%',
    maxWidth: 960,
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    // marginTop: -theme.spacing(8),
    // color: theme.palette.text.secondary,
  },
  input: {
    marginTop: theme.spacing(2),
  },
  spacer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  },
  loginBtn: {
    color: 'white',
  },
  actions: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
}));

const RegistrationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .required('Required'),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  password: Yup.string()
    .min(6, "Too Short!")
    .max(50, 'Too Long!')
    .required('Required'),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('password'), null], "Passwords must match!")
    .required('Required')
});

const valuesFilled = data => Object.values(data).reduce(
  (acc, curr) => {
    if (!acc) return acc;
    return !!curr;
  },
  true
);


const Register = props => {
  const classes = useStyles();
  const history = useHistory();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const handleMouseVisibility = event => event.preventDefault();
  const togglePasswordVisibility = () => setPasswordVisible(!passwordVisible);

  const register = async (data, form) => {
    form.resetForm({ ...form, values: data });
    const result = await props.registerAccount({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      password: data.password,
    });
    if (result) {
      form.resetForm();
      history.push(paths.auth.login);
    }
  };

  const goBackToLogin = () => history.push(paths.auth.login);

  return (
    <div className={classes.root}>
      <div className={classes.spacer}/>
      <Card className={classes.card}>
        <div>
          <Typography className={classes.title} color={'primary'} variant={'h4'}>
            OptionRef
          </Typography>
          <Typography variant={'body1'}>
            User Registration
          </Typography>
        </div>
        <Formik
          initialValues={{ email: '', password: '', passwordConfirm: '', firstName: '', lastName: '' }}
          validationSchema={RegistrationSchema}
          onSubmit={register}>
          {
            (renderProps) => {
              const {values, handleSubmit, touched, errors } = renderProps;
              return (
                <div className={classes.form}>
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6} md={4}>
                        <Field
                          fullWidth
                          error={errors.firstName && touched.firstName}
                          helperText={errors.firstName && touched.firstName ? errors.firstName : ''}
                          className={classes.input}
                          name={'firstName'}
                          value={values.firstName}
                          component={TextField}
                          label={"First Name"}
                          InputProps={{ fullWidth: true }} />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Field
                          fullWidth
                          error={errors.lastName && touched.lastName}
                          helperText={errors.lastName && touched.lastName ? errors.lastName : ''}
                          className={classes.input}
                          name={'lastName'}
                          value={values.lastName}
                          component={TextField}
                          label={"Last Name"}
                          InputProps={{ fullWidth: true }} />
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <Field
                          fullWidth
                          error={errors.email && touched.email}
                          helperText={errors.email && touched.email ? errors.email : ''}
                          className={classes.input}
                          name={'email'}
                          value={values.email}
                          component={TextField}
                          label={"Email"}
                          InputProps={{ fullWidth: true }} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          className={classes.input}
                          fullWidth
                          error={errors.password && touched.password}
                          helperText={errors.password && touched.password ? errors.password : ''}
                          name={'password'}
                          type={passwordVisible ? 'text' : 'password'}
                          value={values.password}
                          label={'Password'}
                          InputProps={{
                            fullWidth: true,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onMouseDown={handleMouseVisibility}
                                  onClick={togglePasswordVisibility}>
                                  {
                                    passwordVisible
                                      ? <VisibilityOff className={classes.passwordIcon} />
                                      : <Visibility color={'primary'} className={classes.passwordIcon} />
                                  }
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                          component={TextField} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          className={classes.input}
                          fullWidth
                          error={errors.passwordConfirm && touched.passwordConfirm}
                          helperText={errors.passwordConfirm && touched.passwordConfirm ? errors.passwordConfirm : ''}
                          name={'passwordConfirm'}
                          type={passwordVisible ? 'text' : 'password'}
                          value={values.passwordConfirm}
                          label={'Password Confirmation'}
                          InputProps={{
                            fullWidth: true,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onMouseDown={handleMouseVisibility}
                                  onClick={togglePasswordVisibility}>
                                  {
                                    passwordVisible
                                      ? <VisibilityOff className={classes.passwordIcon} />
                                      : <Visibility color={'primary'} className={classes.passwordIcon} />
                                  }
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                          component={TextField} />
                      </Grid>
                      <Grid item xs={12}>
                        <div className={classes.actions}>
                          <Button
                            onClick={goBackToLogin}
                            variant={'text'}
                            color={'textSecondary'}>
                            Back To Login
                          </Button>
                          <Button
                            disabled={!valuesFilled(values) || Object.keys(errors).length > 0}
                            type={'submit'}
                            className={classes.loginBtn}
                            variant={'contained'}
                            color={'primary'}>
                            Register
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </form>
                </div>
              )
            }
          }
        </Formik>
      </Card>
      <div className={classes.spacer} />
    </div>
  );
};

Register.propTypes = {
  registerAccount: PropTypes.func.isRequired,
};

export default Register;
