import {denormalizeJsonObject} from "../../helpers";
import * as Yup from 'yup';

export const Schema = Yup.object().shape({
  userId: Yup.string().required(),
  stockId: Yup.string().required(),
})

export const make = (params) => JSON.stringify(
  denormalizeJsonObject({
    subscription: {
      stockId: params.stockId,
      date: new Date(),
      notify: true,
    }
  })
);
export const url = ({ userId }) => `/v1/accounts/user/${userId}/stock`;
