// Wrapper with padding styles for Modal content

import PropTypes from 'prop-types';
import classNames from 'classnames';

import useStyles from './styles';

const ModalContent = ({children, classes}) => {
  const defaultClasses = useStyles();

  return (
    <div className={classNames(defaultClasses.modalContentRoot, classes.root)}>
      {children}
    </div>
  );
};
ModalContent.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
};
ModalContent.defaultProps = {
  classes: {
    root: '',
  },
};

export default ModalContent;
