import {createSelector} from "reselect";
import FetchType from '../../util/fetch-types';


const nonBlockingFetches = [FetchType.history];

export const isFetching = state => state.api.fetching
  .filter(type => !nonBlockingFetches.includes(type))
  .length > 0;

export const isError = state => state.api.meta.isError;
export const getErrors = state => state.api.meta.errors;
export const getFetching = state => state.api.fetching;

export const getError = (fetchType) =>
  createSelector(getErrors,(errors) => errors[fetchType]);

export const isTypeFetching = (fetchType) =>
  createSelector(getFetching,(fetching) => fetching.includes(fetchType));
