// export const optionsRecordKey = (day, year, target) => `${target}.${year}.${day}`;

const pageKey = (page, limit, sort = {}, filter = {}) => {
  const firstPass = Object.keys(sort).reduce(
    (acc, key) => {
      return `${acc}.${key}.${sort[key]}`
    },
    `${page}.${limit}`
  );
  return Object.keys(filter).reduce(
    (acc, key) => {
      return `${acc}.${key}.${filter[key]}`
    },
    firstPass
  );
};

const dayKey = (date, target) => `${target}.${date}`;


export const buildOptionsPageKey = (date, target, params = {}) => {
  const { sort = {}, page, limit, filter = {} } = params;
  const pKey = pageKey(page, limit, sort, filter);
  const dKey = `${dayKey(date, target)}`;
  if (target === 'top') {
    return `${dKey}.${params.balance}`;
  }
  return `${dKey}.${pKey}`;
};


export const buildStockOptionsPageKey = (stockId, params = {}) => {
  const { sort = {}, page, limit, filter = {} } = params;
  return `${stockId}.${pageKey(page, limit, sort, filter)}`;
};
