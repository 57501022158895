import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  patientSummaryRoot: {
    width: '69.27em',
  },
  modalWrapper: {
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
  },
  cancelButton: {
    'height': '3em',
    'color': theme.palette.error.secondary,
    'borderColor': theme.palette.error.secondary,
    '&:hover': {
      borderColor: theme.palette.error.secondary,
    },
  },
  setAvailabilityRoot: {
    [theme.breakpoints.down('sm')]: {
      minWidth: 'calc(100% - 30px)',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: 'calc(100% - 20px)',
    },
  },
  blackoutDatesRoot: {
    [theme.breakpoints.down('xs')]: {
      minWidth: 'calc(100% - 20px)',
    },
  },
  addAddendumRoot: {
    [theme.breakpoints.down('sm')]: {
      minWidth: 'calc(100% - 30px)',
    },
  },
}));

export default useStyles;
