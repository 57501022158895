export default {
  auth: {
    home: '/auth',
    login: '/auth/login',
    register: '/auth/register',
    forgotPassword: '/auth/forgot',
    resetPassword: '/auth/reset/:reset_token',
  },
  dashboard: {
    home: '/',
    account: '/account',
    call: '/calls/:call_date',
    suggestions: '/suggestions/day/:date',
    callResult: '/suggestions/calls/:call_date/results',
    stock: '/stock/:symbol',
    stockResult: '/stock/:symbol/results',
  }
}
