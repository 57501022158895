import * as Yup from 'yup';

export const Schema = Yup.object().shape({
  email: Yup.string().email().required(),
})

export const make = (params) => JSON.stringify({
  email: params.email,
});

export const url = () => '/v1/auth/forgot';
