import {makeAutoObservable, autorun} from 'mobx';
import safeJsonParse from 'util/safeJsonParse';
import pick from 'util/pick';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';
import jwtDecode from 'jwt-decode';
import makeQuery from 'api/makeQuery';

const LS_NAME = 'TIMELY_AUTH';
const WRONG_TOKEN = '__DEFINITELY_WRONG_TOKEN__';

const DEFAULT_STRUCTURE = {
  refreshToken: '',
  token: '',
};

const ALLOWED_KEYS = Object.keys(DEFAULT_STRUCTURE);

const SETTIMEOUT_MAX_VALUE = 2147483647;

class Auth {
  constructor() {
    this.initialize(safeJsonParse(sessionStorage.getItem(LS_NAME)));
    makeAutoObservable(this);

    this.autorefreshTimer = 0;

    autorun(() => {
      sessionStorage.setItem(LS_NAME, JSON.stringify(pick(this, ALLOWED_KEYS)));
    });

    autorun(() => {
      if (this.autorefreshTimer) {
        clearTimeout(this.autorefreshTimer);
      }
      if (this.refreshToken && this.expiresAt) {
        const expiresAtDate = new Date(this.expiresAt);
        const now = new Date();
        const timeDiff = expiresAtDate - now;

        if (timeDiff > 0) {
          console.log(
            `%cShould refresh token in: ${formatDistanceStrict(
              expiresAtDate,
              now,
            )}`,
            'color: green; font-size: 1.5em; text-align: center; font-weight: 600;',
          );
          if (timeDiff < SETTIMEOUT_MAX_VALUE) {
            this.autorefreshTimer = setTimeout(() => this.refresh(), timeDiff);
          }
        }
      }
    });
  }

  async refresh() {
    try {
      if (!this.refreshToken) {
        throw new Error(
          'Refresh token must exist when refresh method is called',
        );
      }
      const authObject = await makeQuery('@postRefreshToken', {
        refreshToken: this.refreshToken,
      });
      this.initialize(authObject);
    } catch (e) {
      this.reset();
    }
  }

  get decoded() {
    return this.token && this.token !== WRONG_TOKEN
      ? jwtDecode(this.token)
      : null;
  }

  get decodedRefresh() {
    return this.refreshToken ? jwtDecode(this.refreshToken) : null;
  }

  get expiresAt() {
    return (this.decoded?.exp ?? 0) * 1000;
  }

  get refreshExpiresAt() {
    return (this.decodedRefresh?.exp ?? 0) * 1000;
  }

  get isExpired() {
    return this.expiresAt < Date.now();
  }

  get isRefreshExpired() {
    return this.refreshExpiresAt < Date.now();
  }

  get shouldRefetchTokenManually() {
    return !this.refreshToken;
  }

  initialize(authObject) {
    Object.assign(this, DEFAULT_STRUCTURE, pick(authObject, ALLOWED_KEYS));
  }

  __invalidateTokenArtificially() {
    this.token = WRONG_TOKEN;
  }

  reset() {
    this.initialize(null);
  }
}

export default Auth;
