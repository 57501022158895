import {connect} from "react-redux";
import {makeStyles} from '@material-ui/core/styles';
import Register from "../pages/Register";
import containerStyle from "./containerStyle";
import * as AccountThunks from '../redux/thunks/accounts';

const useStyles = makeStyles(theme => ({
  root: {
    ...containerStyle.root,
  },
}));

const RegisterContainer = props => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Register {...props}/>
    </div>
  );
};

RegisterContainer.propTypes = {

};

const mapStateToProps = (state) => ({

});


const mapDispatchToProps = (dispatch) => ({
  registerAccount: (account) => dispatch(
    AccountThunks.registerAccount(account)
  ),
});


export default connect(mapStateToProps, mapDispatchToProps)(RegisterContainer);
