import {denormalizeJsonObject} from "../../helpers";
import * as Yup from 'yup';

export const Schema = Yup.object().shape({
  userId: Yup.string().required(),
  optionId: Yup.string().required(),
  callDate: Yup.string().required(),
})


export const make = (params) => JSON.stringify(
  denormalizeJsonObject({
    subscription: {
      optionId: params.optionId,
      callDate: params.callDate,
      notify: true,
    }
  })
);
export const url = ({ userId }) => `/v1/accounts/user/${userId}/option`;
