import camelCase from 'lodash/camelCase';

const QUERY_FILE_REGEX = /((get|post|put|delete)-[\w-]+)\.js$/;
const FILTER_REGEX = /^api\//;

const context = require.context('./v1', true, /\.js$/);
console.log({ keys: context.keys(), context });
const Requests = context.keys().reduce((acc, k) => {
  if (FILTER_REGEX.test(k)) return acc;
  const match = k.match(QUERY_FILE_REGEX);

  if (!match) {
    throw new Error('Bad request file name: ' + k);
  }
  console.log({k, match});
  const queryName = `@${camelCase(match[1])}`;

  if (acc[queryName]) {
    throw new Error(`Query '${queryName}' already exists. Check query naming.`);
  }

  const {
    url,
    Schema,
    make = () => null,
    emptyObjectValue = null,
    doNotConvertKeysToSnakeCase = false,
  } = context(k);

  if (!url || !Schema) {
    throw new Error(
      `'url', 'baseURL' and 'RequestSchema' fields must be present in request file: ${k}`,
    );
  }

  acc[queryName] = {
    url,
    make,
    Schema,
    method: match[2],
    emptyObjectValue,
    doNotConvertKeysToSnakeCase,
  };

  return acc;
}, {});

if (process.env.NODE_ENV !== 'production') {
  console.log(
    '%cSupported requests',
    'color: blue; font-size: 2.5em; text-align: center; font-weight: 600;',
  );
  console.table(Requests, ['method', 'baseURL']);
}

export default Requests;
