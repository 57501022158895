import _ from 'lodash';
import { AccountActions } from '../actions/accounts';


const defaultState = {
  meta: {
    isLoginSuccess: false,
  },
  account: {
    // [account_id]: { Account }
  },
  subscribing: []
};

export default function Accounts(state = defaultState, action) {
  const subscribing = _.cloneDeep(state.subscribing);

  switch (action.type) {
    case AccountActions.SET_SUBSCRIBING:
      if (action.isFetching) {
        if (!subscribing.includes(action.itemId)) {
          subscribing.push(action.itemId);
        } else {
          console.log("subscribing type already exists");
        }
      } else {
        const index = subscribing.indexOf(action.itemId);
        if (index >= 0) {
          subscribing.splice(index, 1)
        }
      }
      return {
        ...state,
        subscribing
      };
    case AccountActions.SET_LOGIN_SUCCESS:
      return {
        ...state,
        meta: {
          ...state.meta,
          isLoginSuccess: action.isSuccess
        }
      };
    case AccountActions.PUT_ACCOUNT: {

      const { account } = action;
      return {
        ...state,
        account: {
          ...state.account,
          [account.accountId]: account,
        }
      };
    }
    default: return state;
  }
}
