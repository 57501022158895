import _ from 'lodash';
import { ApiActions } from '../actions/api';


const defaultState = {
  meta: {
    isFetching: false,
    isLoginSuccess: false,
    isError: false,
    errors: {
      // [fetchType]: "error message"
    }
  },
  fetching: [], // array of fetch types currently being fetched
};

export default function Api(state = defaultState, action) {
  const fetching = _.cloneDeep(state.fetching);

  switch (action.type) {
    case ApiActions.SET_FETCHING:
      if (action.isFetching) {
        if (!fetching.includes(action.fetchType)) {
          fetching.push(action.fetchType);
        } else {
          console.log("fetching type already exists");
        }
      } else {
        const index = fetching.indexOf(action.fetchType);
        if (index >= 0) {
          fetching.splice(index, 1)
        }
      }
      return {
        ...state,
        meta: {
          ...state.meta,
          isFetching: action.isFetching,
        },
        fetching
      };
    case ApiActions.SET_ERROR:
      return {
        ...state,
        meta: {
          ...state.meta,
          errors: {
            ...state.meta.errors,
            [action.fetchType]: action.error
          },
          isError: true,
        }
      };
    case ApiActions.CLEAR_ERROR: {
      const errors = _.omit(state.meta.errors, [action.fetchType]);

      return {
        ...state,
        meta: {
          ...state.meta,
          errors,
          isError: Object.keys(errors).length > 0,
        }
      };
    }
    default: return state;
  }
}
