import * as Yup from 'yup';

export const Schema = Yup.object().shape({
  accountId: Yup.string().min(12, "Too Short").required('Required'),
  transactionId: Yup.string().min(12, "Too Short").required('Required'),
});

export const url = ({ accountId, transactionId }) =>
  `/v1/accounts/${accountId}/transactions/${transactionId}`;

