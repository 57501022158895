import {
  makeAutoObservable,
  reaction,
  runInAction,
} from 'mobx';

import makeQuery from 'api/makeQuery';
import Accounts from 'singletons/Accounts';
import GlobalEvents from 'singletons/GlobalEvents';
import Auth from 'models/Auth';
import safeJsonParse from 'util/safeJsonParse';
import pick from 'util/pick';

const LS_NAME = 'OPTIONREF_USER';
const ALLOWED_LOCALSTORAGE_KEYS = ['userId', 'accountId'];

class User {
  auth = new Auth();
  accountId = '';
  userId = '';
  lastSuccessfulPassword = '';
  temporaryEmail = '';

  constructor() {
    Object.assign(
      this,
      safeJsonParse(sessionStorage.getItem(LS_NAME), ALLOWED_LOCALSTORAGE_KEYS),
    );

    makeAutoObservable(this, {
      needsLogin: false,
      temporaryEmail: false,
      setTemporaryEmail: false,
      resetTemporaryEmail: false,
    });

    reaction(
      () => this.auth.token,
      (token) => {
        if (token) {
          if (this.account) this.account.refetchMe?.();
          else {
            Accounts.get(this.userId);
          }
        }
      },
      {fireImmediately: true},
    );

    reaction(
      () => JSON.stringify(pick(this, ALLOWED_LOCALSTORAGE_KEYS)),
      (lsValue) => sessionStorage.setItem(LS_NAME, lsValue),
      {delay: 500},
    );
  }

  get needsLogin() {
    return !this.memberId || this.auth.shouldRefetchTokenManually;
  }

  get account() {
    return this.userId ? Accounts.get(this.userId) : null;
  }

  async resetPassword(password, resetToken) {
    /*
      Returns:
      {
        unique_id: user.unique_id,
        email: user.email,
        verified: user.verified,
      }
    */
    await makeQuery('@postResetPassword', {
      password,
      resetToken,
    });
  }

  async updatePassword(password, oldPassword) {
    const res = await makeQuery('@putUpdatePassword', {
      password,
      oldPassword,
    });

    this.account?.refetchMe?.();

    GlobalEvents.emit('updateUserPassword', {
      status: 'success',
    });

    return res;
  }

  async login(email, password) {
    const res = await makeQuery('@postLogin', {
      email,
      password,
    }).catch((err) => {
      if (err.response) {
        console.log(err.response);
      }
      return err;
    });

    const {auth, account, stocks, options, transactions } = res;

    console.log({auth, account, stocks, options, transactions});

    runInAction(() => {
      this.lastSuccessfulPassword = password;
      this.auth.initialize(auth);

      if (account?.accountId) {
        this.accountId = account?.accountId;
      }
      if (auth?.userId) {
        this.userId = auth.userId;
        Accounts.upsert(this.userId, {...account});
        Accounts.get(this.userId)?.refetchMe?.();
      }
    });

    return true;
  }

  async handleForgotPassword(email) {
    try {
      const res = await makeQuery('@postForgotPassword', {
        email,
      });

      GlobalEvents.emit('forgotPassword', {
        status: 'success',
        email,
      });
      return res;
    } catch (e) {
      GlobalEvents.emit('forgotPassword', {
        status: 'error',
        email,
      });
    }
  }

  setTemporaryEmail(email) {
    this.temporaryEmail = email?.toLowerCase();
  }

  resetTemporaryEmail() {
    this.setTemporaryEmail('');
  }

  async registerAccount({ email, password, firstName, lastName }) {
    const params = { email, password, firstName, lastName };
    console.log({params});
    const account = await makeQuery('@postMemberRegistration', params);
    runInAction(() => {
      this.accountId = account?.accountId;
      this.userId = account?.userId;
      Accounts.upsert(this.userId, {...account});
    });
    await this.account?.refetchMe?.();
  }

  logout() {
    this.accountId = '';
    this.userId = '';
    this.auth.reset();
    Accounts.clear();
  }
}

export default new User();
