import * as Yup from 'yup';

export const Schema = Yup.object().shape({
  date: Yup.string()
    .matches(/^(\d{4})-\d{2}-\d{2}$/)
    .required(),
  params: Yup.object().shape({
    balance: Yup.number().required()
  })
});

export const url = ({date} = {}) => `/v1/options/top/${date}`;
