import {applyMiddleware, combineReducers, createStore} from "redux";
import thunk from 'redux-thunk';
import View from "./reducers/view";
import Api from "./reducers/api";
import Options from "./reducers/options";
import Stocks from "./reducers/stocks";
import History from "./reducers/history";
import Suggestions from "./reducers/suggestions";
import Results from "./reducers/results";
import Accounts from "./reducers/accounts";
import Transactions from "./reducers/transactions";

const reducers = combineReducers({
  view: View,
  api: Api,
  account: Accounts,
  history: History,
  options: Options,
  stocks: Stocks,
  suggestions: Suggestions,
  results: Results,
  transactions: Transactions
});


export const store = createStore(
  // persistReducer(persistConfig, reducers),
  reducers,
  applyMiddleware(thunk)
);

// export const persistor = persistStore(store);
