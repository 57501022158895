import snakeCase from "lodash/snakeCase";
import isEmpty from "lodash/isEmpty";

export default ({ params }) => {
  const result = new URLSearchParams();
  if (params) {
    const paramKeys = Object.keys(params);
    // console.log({ paramKeys });
    paramKeys.forEach(_key => {
      const key = snakeCase(_key);
      if (typeof params[key] === 'object') {
        const keys = Object.keys(params[key]);
        // console.log({ keys });
        keys.forEach(objectKey => {
          let ok = objectKey;
          if (ok.match(/\./)) {
            ok = ok.split('.')
              .map(part => snakeCase(part))
              .join('.');
          } else {
            ok = snakeCase(objectKey);
          }
          let value = params[ key ][ objectKey ];
          if (objectKey === 'waitTime') {
            ok = 'created_at';
            value = value * -1;
          }
          if (Array.isArray(value)) {
            if (!isEmpty(value)) {
              const finalKey = `${ key }[${ ok }]`;
              value.forEach(
                val => {
                  result.append(finalKey, val);
                }
              );
            }
          } else {
            // console.log({ [`${ key }[${ ok }]`]: value });
            result.append(`${ key }[${ ok }]`, value);
          }
        });
      } else {
        result.append(key, params[key]);
      }
    });
  }
  console.log({ result: result.toString() });
  return result;
};
