import * as Yup from 'yup';

export const Schema = Yup.object().shape({
  userId: Yup.string().required(),
  subscriptionId: Yup.string().required(),
});

export const make = (params) => JSON.stringify({ notify: true });
export const url = ({ userId, subscriptionId }) =>
  `/v1/accounts/user/${userId}/stock/${subscriptionId}`;
