import * as ApiActions from "../actions/api";
import FetchType from "../../util/fetch-types";
import RequestFactory from "../../api/requests/request-factory";
import * as ViewSelectors from "../selectors/view";
import {getFromStorage} from "../../util/storage";
import * as SessionKeys from "../../util/session-constants";
import * as ViewActions from "../actions/view";
import {denormalizeJsonObject, normalizeJsonObject} from "../../api/helpers";
import * as AccountActions from "../actions/accounts";
import {AlertTypes} from "../../util/alert-types";


const executeRequest = async (fetchType, params, dispatch) => {
  const request = RequestFactory.getRequest(fetchType);
  if (!!request && request.isValid(params)) {
    const response = await request.execute(params);
    if (response) {
      await dispatch(receiveAccountResponse(response));
      return response.status > 199 && response.status < 300 ;
    } else {
      const doingByFetchType = () => {
        switch (fetchType) {
          case FetchType.register: return 'creating an account';
          case FetchType.addOptionSub: return 'adding option subscription';
          case FetchType.addStockSub: return 'adding stock subscription';
          case FetchType.updateOptionSubNotify: return 'updating option subscription';
          case FetchType.updateStockSubNotify: return 'updating stock subscription';
          default: return "server";
        }
      };
      const error = 'No response from ' + doingByFetchType();
      console.error(error);
      return false;
    }
  } else {
    console.error('invalid request');
    return false;
  }
};

export const receiveAccountResponse = (response) => async (dispatch, getState) => {
  const isSuccess = !!response.data && !response.data.errors && !!response.data.data;
  console.log('request success? ' + isSuccess);
  dispatch(ViewActions.clearErrors());
  if (isSuccess) {
    const account = normalizeJsonObject(response.data).data;
    await dispatch(AccountActions.putAccount(account));
  } else {
    console.error('request was not successful');
    console.error(response);
  }
};

export const subscribeToStock = (stockId) => async (dispatch, getState) => {
  dispatch(ApiActions.setFetching(FetchType.addStockSub, true));
  try {
    const state = getState();
    const userId = ViewSelectors.getUserId(state) || getFromStorage(SessionKeys.USER_ID);
    const token = ViewSelectors.getAuthToken(state) || getFromStorage(SessionKeys.TOKEN);

    const requestParams = { userId, token, stockId };
    dispatch(AccountActions.setSubscribing(stockId, true));
    await executeRequest(FetchType.addStockSub, requestParams, dispatch);
  } catch (e) {
    console.error('Error subscribing to stock:');
    console.error(e);
  }
  dispatch(AccountActions.setSubscribing(stockId, false));
  dispatch(ApiActions.setFetching(FetchType.addStockSub, false));
};


export const unsubscribeFromStock = (stockId, subscriptionId) => async (dispatch, getState) => {
  dispatch(ApiActions.setFetching(FetchType.removeStockSub, true));
  try {
    const state = getState();
    const userId = ViewSelectors.getUserId(state) || getFromStorage(SessionKeys.USER_ID);
    const token = ViewSelectors.getAuthToken(state) || getFromStorage(SessionKeys.TOKEN);

    const requestParams = { userId, token, subscriptionId };
    dispatch(AccountActions.setSubscribing(stockId, true));
    await executeRequest(FetchType.removeStockSub, requestParams, dispatch);

  } catch (e) {
    console.error('Error unsubscribing to stock:');
    console.error(e);
  }
  dispatch(AccountActions.setSubscribing(stockId, false));
  dispatch(ApiActions.setFetching(FetchType.removeStockSub, false));
};

export const notifyStockSubscription = (subscriptionId, notify = true) => async (dispatch, getState) => {
  dispatch(ApiActions.setFetching(FetchType.updateStockSubNotify, true));
  try {
    const state = getState();
    const userId = ViewSelectors.getUserId(state) || getFromStorage(SessionKeys.USER_ID);
    const token = ViewSelectors.getAuthToken(state) || getFromStorage(SessionKeys.TOKEN);

    const requestParams = { userId, token, subscriptionId, notify };
    await executeRequest(FetchType.updateStockSubNotify, requestParams, dispatch);
  } catch (e) {
    console.error('Error updating stock notification:');
    console.error(e);
  }
  dispatch(ApiActions.setFetching(FetchType.updateStockSubNotify, false));
};


export const subscribeToOption = (suggestionId, optionId, callDate) => async (dispatch, getState) => {
  dispatch(ApiActions.setFetching(FetchType.addOptionSub, true));
  try {
    const state = getState();
    const userId = ViewSelectors.getUserId(state) || getFromStorage(SessionKeys.USER_ID);
    const token = ViewSelectors.getAuthToken(state) || getFromStorage(SessionKeys.TOKEN);

    const requestParams = { userId, token, suggestionId, optionId, callDate };
    dispatch(AccountActions.setSubscribing(optionId, true));
    await executeRequest(FetchType.addOptionSub, requestParams, dispatch);

  } catch (e) {
    console.error('Error subscribing to option:');
    console.error(e);
  }
  dispatch(AccountActions.setSubscribing(optionId, false));
  dispatch(ApiActions.setFetching(FetchType.addOptionSub, false));
};


export const unsubscribeFromOption = (optionId, subscriptionId) => async (dispatch, getState) => {
  dispatch(ApiActions.setFetching(FetchType.removeOptionSub, true));
  try {
    const state = getState();
    const userId = ViewSelectors.getUserId(state) || getFromStorage(SessionKeys.USER_ID);
    const token = ViewSelectors.getAuthToken(state) || getFromStorage(SessionKeys.TOKEN);

    const requestParams = { userId, token, subscriptionId };
    dispatch(AccountActions.setSubscribing(optionId, true));
    await executeRequest(FetchType.removeOptionSub, requestParams, dispatch);

  } catch (e) {
    console.error('Error unsubscribing to option:');
    console.error(e);
  }
  dispatch(AccountActions.setSubscribing(optionId, false));
  dispatch(ApiActions.setFetching(FetchType.removeOptionSub, false));
};



export const notifyOptionSubscription = (subscriptionId, notify = true) => async (dispatch, getState) => {
  dispatch(ApiActions.setFetching(FetchType.updateOptionSubNotify, true));
  try {
    const state = getState();
    const userId = ViewSelectors.getUserId(state) || getFromStorage(SessionKeys.USER_ID);
    const token = ViewSelectors.getAuthToken(state) || getFromStorage(SessionKeys.TOKEN);

    const requestParams = { userId, token, subscriptionId, notify };
    await executeRequest(FetchType.updateOptionSubNotify, requestParams, dispatch);

  } catch (e) {
    console.error('Error updating option notification:');
    console.error(e);
  }
  dispatch(ApiActions.setFetching(FetchType.updateOptionSubNotify, false));
};


export const registerAccount = (account) => async (dispatch, getState) => {
  dispatch(ApiActions.setFetching(FetchType.register, true));
  let success = false;
  try {
    const payload = denormalizeJsonObject(account);
    // console.log({ payload });
    success = await executeRequest(FetchType.register, payload, dispatch);
    if (success) {
      dispatch(
        ViewActions.putAlert(
          "Success!",
          "Your account has been successfully created, now please log in",
          AlertTypes.success
        )
      );
    } else {
      dispatch(
        ViewActions.putAlert(
          "Error",
          "We ran into an issue creating your account...",
          AlertTypes.error
        )
      );
    }
  } catch (e) {
    console.error('Error creating account');
    console.error(e);
    const error = e.response && e.response.data && e.response.data.errors
      ? e.response.data.errors[0].message
      : e.reason || e.message;
    dispatch(
      ViewActions.putAlert(
        "Error",
        error,
        AlertTypes.success
      )
    );
  }

  dispatch(ApiActions.setFetching(FetchType.register, false));
  return success;
};


export const resetPassword = (password, resetToken) => async (dispatch, getState) => {
  dispatch(ApiActions.setFetching(FetchType.reset, true));
  let success = false;
  try {
    const request = RequestFactory.getRequest(FetchType.reset);
    const params = { password, reset_token: resetToken };
    if (!!request && request.isValid(params)) {
      const response = await request.execute(params);
      if (response) {
        switch (response.status) {
          case 200:
          case 201:
          case 204: {
            success = true;
            break;
          }
          default: break;
        }
      } else {
        console.error('no response from api');
      }
    } else {
      console.error('request is invalid')
    }

    if (success) {
      dispatch(
        ViewActions.putAlert(
          "Success!",
          "Your password has been successfully reset, now please log in",
          AlertTypes.success
        )
      );
    } else {
      dispatch(
        ViewActions.putAlert(
          "Error",
          "We ran into an issue resetting your password...",
          AlertTypes.error
        )
      );
    }
  } catch (e) {
    console.error('Error resetting password');
    console.error(e);
    const error = e.response && e.response.data && e.response.data.errors
      ? e.response.data.errors[0].message
      : e.reason || e.message;
    dispatch(
      ViewActions.putAlert(
        "Error",
        error,
        AlertTypes.error
      )
    );
  }

  dispatch(ApiActions.setFetching(FetchType.reset, false));
  return success;
};


export const forgotPassword = (email) => async (dispatch, getState) => {
  dispatch(ApiActions.setFetching(FetchType.forgotPassword, true));
  let success = false;
  try {
    const request = RequestFactory.getRequest(FetchType.forgotPassword);
    const params = { email };
    if (!!request && request.isValid(params)) {
      const response = await request.execute(params);
      if (response) {
        switch (response.status) {
          case 200:
          case 201:
          case 204: {
            success = true;
            break;
          }
          default: break;
        }
      } else {
        console.error('no response from api');
      }
    } else {
      console.error('request is invalid')
    }

    if (success) {
      dispatch(
        ViewActions.putAlert(
          "Success!",
          "Check your email to continue",
          AlertTypes.success
        )
      );
    } else {
      dispatch(
        ViewActions.putAlert(
          "Error",
          "We ran into an issue resetting your password...",
          AlertTypes.error
        )
      );
    }
  } catch (e) {
    console.error('Error resetting password');
    console.error(e);
    const error = e.response && e.response.data && e.response.data.errors
      ? e.response.data.errors[0].message
      : e.reason || e.message;
    dispatch(
      ViewActions.putAlert(
        "Error",
        error,
        AlertTypes.error
      )
    );
  }

  dispatch(ApiActions.setFetching(FetchType.forgotPassword, false));
  return success;
};
