import {runInAction} from 'mobx';
import CollectionBase from "models/CollectionBase";
import Option from 'models/Option';
import mergeMobxListsBy from "util/mergeMobxListsBy";
import makeQuery from "api/makeQuery";
import isEmpty from 'lodash/isEmpty';
import {getCurrentOptionsDate} from "util/date";

/*
  Sorting priority by scheduledAt

  true - false === 1;
  false - true === -1;
*/

const stringifyParameters = (params) => {
  const sorted = Object.keys(params)
    .sort()
    .reduce((acc, key) => {
      if (typeof params[key] === 'object') {
        acc[key] = stringifyParameters(params[key]);
      } else {
        acc[key] = params[key];
      }
      return acc;
    }, {});
  return JSON.stringify(sorted);
}

class SearchOptionsCollection extends CollectionBase {
  parameters = {};
  page = 1;
  count = 0
  resultCache = new Map();

  async refetch() {
    return this.search(this.parameters, true);
  }

  cacheResults(key, results) {
    runInAction(() => {
      this.resultCache.set(key, results);
    });
  }

  get topOptionParams() {
    const date = getCurrentOptionsDate();
    return {
      date,
      params: {
        balance: this.account?.balance || 2000
      }
    };
  }

  get hasMoreResults() {
    return this.list.length < this.count;
  }


  async getTopOptions(noCache = false) {
    const mapKey = stringifyParameters(this.topOptionParams);
    if (!noCache && this.resultCache.has(mapKey)) {
      this.mergeOptions(
        this.resultCache.get(mapKey),
        true
      );
      return;
    }
    const data = await makeQuery('@getTopOptions', this.topOptionParams);
    const results = data.reduce(
      (acc, item) => {
        return acc.concat(item.options);
      }, []
    );
    runInAction(() => {
      this.cacheResults(mapKey, results);
      this.mergeOptions(results, true);
      this.page = 1;
      this.count = results.length;
    })
  }

  async search(params, noCache = false) {
    if (isEmpty(params)) {
      await this.getTopOptions(noCache);
    } else {
      this.parameters = params;
      const mapKey = stringifyParameters(params);
      if (!noCache && this.resultCache.has(mapKey)) {
        this.mergeOptions(this.resultCache.get(mapKey), true);
        return;
      }
      const response = await makeQuery('@getOptions', {
        params: {
          ...params,
          limit: 50,
          page: 1,
        }
      });
      runInAction(() => {
        this.cacheResults(mapKey, response?.paginated);
        this.mergeOptions(response.paginated, true);
        this.page = 1;
        this.count = response?.meta?.count;
      });
    }
  }

  async fetchMore() {
    if (!this.hasMoreResults) return;
    const mapKey = stringifyParameters(this.parameters);
    const response = await makeQuery('@getOptions', {
      params: {
        ...this.parameters,
        limit: 50,
        page: this.page + 1,
      }
    });
    runInAction(() => {
      this.cacheResults(mapKey, response?.paginated);
      this.mergeOptions(response.paginated, false);
      this.page += 1;
      this.count = response?.meta?.count;
    })
  }

  mergeOptions(options, shouldDelete = false) {
    runInAction(() =>
      mergeMobxListsBy(
        this.map,
        this.list,
        options,
        'optionId',
        (v) => new Option(this.account, v),
        shouldDelete,
      ),
    );
  }

}

export default SearchOptionsCollection;
