import _ from 'lodash';
import {ResultActionTypes} from "../actions/results";


const defaultState = {
  meta: {

  },
  records: {

  }
};

const getId = result => result.resultId;

export default function Results(state = defaultState, action) {
  const records = _.cloneDeep(state.records);

  switch (action.type) {
    case ResultActionTypes.PUT_RESULT: {
      const { result } = action;
      records[getId(result)] = result;
      return {
        ...state,
        records
      };
    }
    case ResultActionTypes.PUT_RESULTS: {
      const { results } = action;
      results.forEach(result => {
        records[getId(result)] = result
      });
      return {
        ...state,
        records
      };
    }
    case ResultActionTypes.CLEAR_RESULT: {
      const { resultId } = action;
      delete records[resultId];
      return {
        ...state,
        records
      };
    }
    case ResultActionTypes.CLEAR_RESULTS: {
      return {
        ...state,
        records: {}
      };
    }
    default: return state;
  }
}
