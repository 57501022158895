import getThemeMerger from 'util/getThemeMerger';

export default getThemeMerger((currentTheme) => {
  console.log({currentTheme});
  return {
    overrides: {
      MuiDialog: {
        /* TODO:
          too small values lead to invisible period removal button in blackouts modal;
          did:
            31 -> 35em
            25 -> 29em
          to make button visible again. @HOTFIX
        */
        paperWidthSm: {
          minWidth: '35em',
          maxWidth: '90vw',
          [currentTheme.breakpoints.down('sm')]: {
            minWidth: '29em',
            margin: `${currentTheme.spacing(4)}px ${currentTheme.spacing(2)}px`,
          },
          [currentTheme.breakpoints.down(400)]: {
            minWidth: '18em',
          },
        },
      },
      MuiDialogContent: {
        dividers: {
          padding: 0,
          borderTop: `1px solid ${currentTheme.palette.dashboard.card2}`,
          borderBottom: `1px solid ${currentTheme.palette.dashboard.card2}`,
        },
      },
      MuiDialogActions: {
        root: {
          minHeight: '6em',
        },
      },
      MuiTypography: {
        body1: {
          letterSpacing: 0.5,
        },
      },
    },
  };
});
