// import {Redirect} from "react-router";
import Auth from "./Auth";
import paths from './paths';
import RegisterContainer from "../containers/RegisterContainer";
import Login from "../pages/Login";
import ForgotPasswordContainer from "../containers/ForgotPasswordContainer";
import ResetPasswordContainer from "../containers/ResetPasswordContainer";
import HomeRedirect from "./HomeRedirect";
// import HomeContainer from "../containers/HomeContainer";
// import AccountContainer from "../containers/AccountContainer";
// import CallContainer from "../containers/CallContainer";
// import CallResultContainer from "../containers/CallResultContainer";
// import StockContainer from "../containers/StockContainer";
// import StockResultContainer from "../containers/StockResultContainer";
// import HomeStack from "./HomeStack";
// import SuggestionsContainer from "../containers/SuggestionsContainer";

export default [
  {
    component: Auth,
    path: paths.auth.home,
    routes: [
      {
        component: RegisterContainer,
        exact: true,
        path: paths.auth.register,
      },
      {
        component: Login,
        exact: true,
        path: paths.auth.login,
      },
      {
        component: ForgotPasswordContainer,
        exact: true,
        path: paths.auth.forgotPassword,
      },
      {
        component: ResetPasswordContainer,
        exact: true,
        path: paths.auth.resetPassword,
      },
    ],
  },
  // {
  //   component: HomeStack,
  //   path: paths.dashboard.home,
  //   isPrivate: true,
  //   routes: [
  //     {
  //       component: HomeContainer,
  //       exact: true,
  //       isPrivate: true,
  //       path: paths.dashboard.home,
  //     },
  //     {
  //       component: AccountContainer,
  //       exact: true,
  //       isPrivate: true,
  //       path: paths.dashboard.account,
  //     },
  //     {
  //       component: CallContainer,
  //       exact: true,
  //       isPrivate: true,
  //       path: paths.dashboard.call,
  //     },
  //     {
  //       component: CallResultContainer,
  //       exact: true,
  //       isPrivate: true,
  //       path: paths.dashboard.callResult,
  //     },
  //     {
  //       component: StockContainer,
  //       exact: true,
  //       isPrivate: true,
  //       path: paths.dashboard.stock,
  //     },
  //     {
  //       component: StockResultContainer,
  //       exact: true,
  //       isPrivate: true,
  //       path: paths.dashboard.stockResult,
  //     },
  //     {
  //       component: SuggestionsContainer,
  //       exact: true,
  //       isPrivate: true,
  //       path: paths.dashboard.suggestions,
  //     },
  //   ]
  // },
  {
    component: HomeRedirect,
    path: `${paths.dashboard.home}/*`
  },
  {
    component: HomeRedirect,
    path: '*'
  },
];
