import {/* lazy, */Suspense, useCallback} from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import Modals from 'singletons/Modals';
import Modal from 'components/Modal';
import useStyles from './styles';

const ModalsMap = {
  ineligibleAlert: {
    // Component: lazy(() => import('./IneligibleAlert')),
    // label: 'Unable to Verify Eligibility',
    // titleCancelButtonVariant: false,
    // rootClassName: '',
  },
};
const ModalWrapper = ({type, id, options}) => {
  const classes = useStyles();
  const closeHandler = useCallback(() => Modals.close(id), [id]);
  console.log({type, id, options});
  const {Component, label, titleCancelButtonVariant, rootClassName} =
    ModalsMap[type];
  const finalLabel = label
    ? typeof label === 'function'
      ? label(options)
      : label
    : null;
  return (
    Component && (
      <Modal
        open
        onClose={closeHandler}
        title={finalLabel}
        titleCancelButtonVariant={titleCancelButtonVariant}
        dialogClasses={{paper: classes[`${rootClassName}Root`]}}
      >
        <Suspense fallback={`Loading modal: ${type}`}>
          <Component {...options} closeModal={closeHandler} />
        </Suspense>
      </Modal>
    )
  );
};
ModalWrapper.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  options: PropTypes.object,
};
const ModalsContainer = () =>
  Modals.list.map((modalParams) => (
    <ModalWrapper key={modalParams.id} {...modalParams} />
  ));
export default observer(ModalsContainer);
