import _ from 'lodash';
import {HistoryActionTypes} from "../actions/history";


const defaultState = {
  meta: {

  },
  records: {

  }
};

export default function History(state = defaultState, action) {
  const records = _.cloneDeep(state.records);

  switch (action.type) {
    case HistoryActionTypes.PUT_HISTORY: {
      const { history } = action;
      if (!records[history.stockId]) records[history.stockId] = {};
      records[history.stockId][history.year] = history;
      return {
        ...state,
        records
      };
    }
    case HistoryActionTypes.PUT_HISTORIES: {
      const { histories } = action;
      histories.reduce((acc, history) => {
        if (!acc[history.stockId]) acc[history.stockId] = {};
        acc[history.stockId][history.year] = history;
        return acc;
      }, records);
      return {
        ...state,
        records
      };
    }
    case HistoryActionTypes.CLEAR_HISTORY: {
      const { historyId } = action;
      delete records[historyId];
      return {
        ...state,
        records
      };
    }
    case HistoryActionTypes.CLEAR_HISTORIES: {
      return {
        ...state,
        records: {}
      };
    }
    default: return state;
  }
}
