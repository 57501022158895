import {createSelector} from "reselect";

export const getLogin = (state) => state.view.login;
export const getDialogs = (state) => state.view.dialogs;
export const getAlert = (state) => state.view.alert;
export const getWindow = (state) => state.view.window;

export const getWindowWidth = createSelector(getWindow, (window) => window && window.width);
export const getLoginUser = createSelector(getLogin, (login) => login.user);

export const isAlerting = createSelector(getAlert, (alert) => alert && alert.isAlert);
export const getUserId = createSelector(getLogin, (user) => user.userId);
export const getAuthToken = createSelector(getLogin, (user) => user.token);
export const isLoggedIn = createSelector(getUserId, (userId) => !!userId);
