const TAG = 'ACCOUNT';


const SET_SUBSCRIBING = `${TAG}setSubscribing`;
export const setSubscribing = (itemId, isFetching) => ({ type: SET_SUBSCRIBING, isFetching, itemId});

const SET_LOGIN_SUCCESS = `${TAG}_setLoginSuccess`;
export const setLoginSuccess = (isSuccess) => ({ type: SET_LOGIN_SUCCESS, isSuccess});

const PUT_ACCOUNT = `${TAG}_putAccount`;
export const putAccount = (account) => ({ type: PUT_ACCOUNT, account});

export const AccountActions = {
  SET_SUBSCRIBING,
  SET_LOGIN_SUCCESS,
  PUT_ACCOUNT
};
