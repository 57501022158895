const TAG = 'RESULTS';

const PUT_RESULT = `${TAG}/putResult`;
export const putResult = (result) => ({ type: PUT_RESULT, result });

const CLEAR_RESULT = `${TAG}/clearResult`;
export const clearResult = (resultId) => ({ type: CLEAR_RESULT, resultId });

const PUT_RESULTS = `${TAG}/putResults`;
export const putResults = (results) => ({ type: PUT_RESULTS, results });

const CLEAR_RESULTS = `${TAG}/clearResults`;
export const clearResults = () => ({ type: CLEAR_RESULTS });


export const ResultActionTypes = {
  PUT_RESULT,
  PUT_RESULTS,
  CLEAR_RESULT,
  CLEAR_RESULTS
};
