import PropTypes from 'prop-types';
import classNames from 'classnames';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'white',
    display: 'flex',
    borderRadius: 12,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: 'rgba(0,0,0,.12) 0px 0px 30px',
  },
  leftAligned: {
    alignItems: 'flex-start'
  },
  topJustified: {
    justifyContent: 'flex-start'
  }
}));

const Card = props => {
  const classes = useStyles();
  const others = [];
  if (props.leftAligned) {
    others.push(classes.leftAligned);
  }
  if (props.topJustified) {
    others.push(classes.topJustified);
  }
  if (props.className) {
    others.push(props.className);
  }
  return (
    <div className={classNames(classes.root, ...others)} style={props.style}>
      { props.children }
    </div>
  );
};

Card.propTypes = {
  leftAligned: PropTypes.bool,
  topJustified: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.any,
  children: PropTypes.node,
};

export default Card;
