import fromPairs from 'lodash/fromPairs';

const defaultValueTransformer = (v) => v;
const EMPTY_OBJECT_VALUE = null;

const getTransformer = (
  transformKey,
  transformValue = defaultValueTransformer,
  emptyObjectValue = EMPTY_OBJECT_VALUE
) =>
  function normalize(value) {
    if (value) {
      if (typeof value === 'object' && value.constructor === Object) {
        const keys = Object.keys(value);

        return keys.length
          ? fromPairs(keys.map((k) => [transformKey(k), normalize(value[k])]))
          : emptyObjectValue;
      }

      if (Array.isArray(value)) {
        return value.map(normalize);
      }
    }

    return transformValue(value);
  };

export default getTransformer;
