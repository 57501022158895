const TAG = 'STOCKS';

const PUT_STOCK = `${TAG}/putStock`;
export const putStock = (stock) => ({ type: PUT_STOCK, stock });

const PUT_STOCK_LAST_FETCH = `${TAG}/putStockLastFetch`;
export const putStockLastFetch = (stockId, lastFetch) => ({ type: PUT_STOCK_LAST_FETCH, stockId, lastFetch });

const CLEAR_STOCK = `${TAG}/clearStock`;
export const clearStock = (stockId) => ({ type: CLEAR_STOCK, stockId });

const PUT_STOCKS = `${TAG}/putStocks`;
export const putStocks = (stocks) => ({ type: PUT_STOCKS, stocks });

const PUT_LAST_FETCH = `${TAG}/putLastFetch`;
export const putLastFetch = (lastFetch) => ({ type: PUT_LAST_FETCH, lastFetch });

const CLEAR_STOCKS = `${TAG}/clearStocks`;
export const clearStocks = () => ({ type: CLEAR_STOCKS });


export const StockActionTypes = {
  PUT_STOCK,
  PUT_LAST_FETCH,
  PUT_STOCK_LAST_FETCH,
  PUT_STOCKS,
  CLEAR_STOCK,
  CLEAR_STOCKS
};
