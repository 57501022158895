import _ from 'lodash';
import {StockActionTypes} from "../actions/stocks";


const defaultState = {
  meta: {
    lastFetchAt: null,
    fetches: {
      // [stockId]: lastFetch
    }
  },
  records: {

  }
};

const getId = stock => stock.stockId || stock._id || stock.id;

export default function Stocks(state = defaultState, action) {
  const records = _.cloneDeep(state.records);
  const meta = _.cloneDeep(state.meta);

  switch (action.type) {
    case StockActionTypes.PUT_STOCK: {
      const { stock } = action;
      records[getId(stock)] = stock;
      return {
        ...state,
        records
      };
    }
    case StockActionTypes.PUT_LAST_FETCH: {
      const { lastFetch } = action;
      meta.lastFetchAt = lastFetch;
      return {
        ...state,
        meta
      };
    }
    case StockActionTypes.PUT_STOCK_LAST_FETCH: {
      const { lastFetch, stockId } = action;
      if (!meta.fetches) meta.fetches = {};
      meta.fetches[stockId] = lastFetch;
      return {
        ...state,
        meta
      };
    }
    case StockActionTypes.PUT_STOCKS: {
      const { stocks } = action;
      console.log({ action });
      stocks.forEach(stock => {
        records[getId(stock)] = stock
      });
      return {
        ...state,
        meta: {
          ...state.meta,
          lastFetchAt: new Date()
        },
        records
      };
    }
    case StockActionTypes.CLEAR_STOCK: {
      const { stockId } = action;
      delete records[stockId];
      return {
        ...state,
        meta: {
          ...state.meta,
          lastFetchAt: null
        },
        records
      };
    }
    case StockActionTypes.CLEAR_STOCKS: {
      return {
        ...state,
        records: {}
      };
    }
    default: return state;
  }
}
