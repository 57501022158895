const TAG = 'VIEW';

const SET_USER_ID = `${TAG}_setUserId`;
export const setUserId = (userId) => ({ type: SET_USER_ID, userId });


const SET_USER = `${TAG}_setUser`;
export const setUser = (user) => ({ type: SET_USER, user });

const SET_AUTH_TOKEN = `${TAG}_setAuthToken`;
export const setAuthToken = (token) => ({ type: SET_AUTH_TOKEN, token });

const SET_REFRESH_TOKEN = `${TAG}_setRefreshToken`;
export const setRefreshToken = (refreshToken) => ({ type: SET_REFRESH_TOKEN, refreshToken });

const PUT_ALERT = `${TAG}_putAlert`;
export const putAlert = (title, body, alertType) => ({ type: PUT_ALERT, title, body, alertType});

const SET_ALERT_VISIBLE = `${TAG}_setAlertVisible`;
export const setAlertVisible = (visible) => ({ type: SET_ALERT_VISIBLE, visible });

const PUT_ERROR = `${TAG}_putError`;
export const putError = (error) => ({ type: PUT_ERROR, error });

const UPDATE_WINDOW_SIZE = `${TAG}_updateWindowSize`;
export const updateWindowSize = ({height, width}) => ({ type: UPDATE_WINDOW_SIZE, height, width });

const PUT_FIELD_ERROR = `${TAG}_putFieldError`;
export const putFieldError = (field, error) => ({ type: PUT_FIELD_ERROR, field, error });

const CLEAR_ERRORS = `${TAG}_clearErrors`;
export const clearErrors = () => ({ type: CLEAR_ERRORS });


export const ViewActionTypes = {
  SET_USER_ID,
  SET_USER,
  SET_AUTH_TOKEN,
  SET_REFRESH_TOKEN,
  PUT_ERROR,
  CLEAR_ERRORS,
  PUT_FIELD_ERROR,
  SET_ALERT_VISIBLE,
  PUT_ALERT,
  UPDATE_WINDOW_SIZE,
};
