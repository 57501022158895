import {action, makeObservable, runInAction} from 'mobx';
import groupBy from 'lodash/groupBy';
import CollectionBase from "models/CollectionBase";
import makeQuery from "api/makeQuery";
import mergeMobxListsBy from "util/mergeMobxListsBy";
import Transaction from "../../Transaction";

/*
  Sorting priority by scheduledAt

  true - false === 1;
  false - true === -1;
*/
// const createdAtAsc = (a, b) => (a.createdAt > b.createdAt ? -1 : 1);
// const createdAtDesc = (a, b) => (a.createdAt > b.createdAt ? 1 : -1);

class TransactionsCollection extends CollectionBase {
  list = [];
  map = new Map();

  constructor(account) {
    super(account);
    makeObservable(this, {
      list: true,
      map: true,
      length: true,

      bySymbol: true,
      byStockId: true,
      undoTransaction: action,
      depositToAccount: action,
      refetch: action,
    });
    this.account = account;
  }

  async refetch() {
    const transactions = await makeQuery('@getAccountTransactions', {
      accountId: this.account?.accountId
    });
    if (transactions?.length) {
      runInAction(() =>
        mergeMobxListsBy(
          this.map,
          this.list,
          transactions,
          'stockId',
          (v) => new Transaction(this.account, v),
          true,
        ),
      )
    }
  }

  async depositToAccount({amount, fee = 0}) {
    await makeQuery('@postDepositTransaction', {
      accountId: this.account.accountId,
      transaction: {
        userId: this.account.userId,
        accountId: this.account.accountId,
        amount,
        fee,
        quantity: 1,
        deposit: true,
      }
    });
    await this.refetch();
  }


  async undoTransaction(transactionId) {
    await makeQuery('@deleteOptionTransaction', {
      accountId: this.account.accountId,
      transactionId
    });
    await this.refetch();
  }

  destroy() {
    for (const visit of this.list) {
      visit.destroy();
    }
  }

  getById(id) {
    return this.map.get(id);
  }

  getBySymbol(symbol) {
    return this.bySymbol[symbol]
  }

  getByStockId(stockId) {
    return this.byStockId[stockId]
  }

  get length() {
    return this.list.length;
  }

  get bySymbol() {
    return groupBy(this.list, 'symbol');
  }

  get byStockId() {
    return groupBy(this.list, 'stockId');
  }
}

export default TransactionsCollection;
