const TAG = 'TRANSACTIONS';

const PUT_TRANSACTION = `${TAG}/putTransaction`;
export const putTransaction = (transaction) => ({ type: PUT_TRANSACTION, transaction });

const CLEAR_TRANSACTION = `${TAG}/clearTransaction`;
export const clearTransaction = (transactionId) => ({ type: CLEAR_TRANSACTION, transactionId });

const PUT_TRANSACTIONS = `${TAG}/putTransactions`;
export const putTransactions = (transactions) => ({ type: PUT_TRANSACTIONS, transactions });

const PUT_LAST_FETCH = `${TAG}/putLastFetch`;
export const putLastFetch = (lastFetch) => ({ type: PUT_LAST_FETCH, lastFetch });

const CLEAR_TRANSACTIONS = `${TAG}/clearTransactions`;
export const clearTransactions = () => ({ type: CLEAR_TRANSACTIONS });


export const TransactionActionTypes = {
  PUT_LAST_FETCH,
  PUT_TRANSACTION,
  PUT_TRANSACTIONS,
  CLEAR_TRANSACTIONS,
  CLEAR_TRANSACTION
};
