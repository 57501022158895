import _ from 'lodash';
import {AlertTypes} from "../util/alert-types";
import * as ViewActions from "../redux/actions/view";


// Normalization helpers

export const normalizeJsonArray = (array = []) => {
  return array.map(item => {
    if (Array.isArray(item)) {
      return normalizeJsonArray(item);
    } else if (typeof item === 'object') {
      return normalizeJsonObject(item);
    } else {
      return item
    }
  })
};

// eslint-disable-next-line prefer-regex-literals
const mongoIdRegex = new RegExp(/^[0-9a-fA-F]{24}$/);
export const normalizeJsonObject = (data = {}) =>{
  return Object.keys(data||{}).reduce((acc, key) => {
      const normalizedKey = mongoIdRegex.test(key) ? key : _.camelCase(key);
      if (Array.isArray(data[key])) {
        acc[normalizedKey] = normalizeJsonArray(data[key])
      } else if (data[key] === null) {
        acc[normalizedKey] = null;
      } else if (typeof data[key] === 'object' && !!data[key]) {
        acc[normalizedKey] = normalizeJsonObject(data[key])
      } else {
        acc[normalizedKey] = data[key];
      }
      return acc;
    },
    {}
  );
};


export const denormalizeJsonArray = (array = [], emptyObjectValue = null, idField = '_id') => {
  return array.map(item => {
    if (Array.isArray(item)) {
      return denormalizeJsonArray(item, emptyObjectValue, idField);
    } else if (typeof item === 'object') {
      return denormalizeJsonObject(item, emptyObjectValue, idField);
    } else {
      return item
    }
  })
};

export const denormalizeJsonObject = (data = {}, emptyObjectValue = null, idField = '_id') =>{
  return Object.keys(data||{}).reduce((acc, key) => {
      let normalizedKey = _.snakeCase(key);
      if (_.isEqual(normalizedKey, 'id')) {
        normalizedKey = idField;
      }
      if (Array.isArray(data[key])) {
        acc[normalizedKey] = denormalizeJsonArray(data[key], emptyObjectValue, idField)
      } else if (typeof data[key] === 'object') {
        if (data[key] === null || Object.keys(data[key]).length === 0) {
          acc[normalizedKey] = emptyObjectValue
        } else {
          acc[normalizedKey] = denormalizeJsonObject(data[key], emptyObjectValue, idField)
        }
      } else {
        acc[normalizedKey] = data[key];
      }
      return acc;
    },
    {}
  );
};

export const handleRequestFailure = (dispatch, callback) => (error) => {
  dispatch(ViewActions.putAlert("Error", error, AlertTypes.error));
  if (callback) callback();
};
