import AuthRoute from "./AuthRoute";
import PropTypes from "prop-types";
const RouteBranch = ({ path, component: Comp, routes, location, isPrivate, exact }) => {
  return (
    <AuthRoute
      path={path}
      location={location}
      isPrivate={isPrivate}
      exact={exact}
      render={
        (props) => <Comp {...props} routes={routes}/>
      } />
  );
};

RouteBranch.propTypes = {
  path: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.node]),
  routes: PropTypes.array,
  location: PropTypes.object,
  isPrivate: PropTypes.bool,
  exact: PropTypes.bool,
};

export default RouteBranch;
