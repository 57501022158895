import PropTypes from 'prop-types';

import { Backdrop, CircularProgress, Hidden } from '@material-ui/core';
import classNames from 'classnames';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => {
    return ({
      container: {
        position: 'fixed',
        pointerEvents: 'all',

        zIndex: 5,
        backgroundColor: 'rgba(255,255,255, 0.5)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'all 400ms',
      },
      full: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: '100%',
        width: '100%',
      },
      drawer: {
        top: 0,
        right: 0,
        bottom: 0,
        height: '100%',
        width: `calc(100% - ${theme.dimens.drawerWidth}px)`,
        left: theme.dimens.drawerWidth,
      }
    });
  }
);

const Loading = (props) => {
  const classes = useStyles();
  const {noDrawer, loading} = props;
  return (
    <div>
      <Hidden mdUp>
        <Backdrop open={loading} className={classNames(classes.container, classes.full)}>
          <CircularProgress variant={'indeterminate'} />
        </Backdrop>
      </Hidden>
      <Hidden smDown>
        <Backdrop open={loading} className={classNames(classes.container, noDrawer ? classes.full : classes.drawer)}>
          <CircularProgress variant={'indeterminate'} />
        </Backdrop>
      </Hidden>
    </div>
  );
};

Loading.propTypes = {
  noDrawer: PropTypes.bool,
  loading: PropTypes.bool
};

export default Loading;

