import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {Field, Formik} from "formik";
import {TextField} from "formik-material-ui";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import * as Yup from 'yup';
import Card from "../components/Card";
import pageStyle from "./pageStyle";
import paths from "../routes/paths";
import {useHistory} from "react-router";

const useStyles = makeStyles(theme => ({
  root: {
    ...pageStyle.root,
    padding: `0 ${theme.spacing(2)}px`,
  },
  passwordIcon: {
    color: '#999999'
  },
  card: {
    margin: `${theme.spacing(4)}px ${theme.spacing(2)}px`,
    width: '100%',
    maxWidth: 500,
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    // marginTop: -theme.spacing(8),
    // color: theme.palette.text.secondary,
  },
  input: {
    marginTop: theme.spacing(2),
  },
  spacer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  },
  loginBtn: {
    color: 'white',
  },
  actions: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
}));

const ForgotSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
});

const valuesFilled = data => Object.values(data).reduce(
  (acc, curr) => {
    if (!acc) return acc;
    return !!curr;
  },
  true
);

const ForgotPassword = props => {
  const classes = useStyles();
  const history = useHistory();

  const forgotPass = async (data, form) => {
    form.resetForm({ ...form, values: data });
    const result = await props.forgotPassword(data.email);
    if (result) {
      form.resetForm();
      history.push(paths.auth.login);
    }
  };

  const goBackToLogin = () => history.push(paths.auth.login);

  return (
    <div className={classes.root}>
      <div className={classes.spacer}/>
      <Card className={classes.card}>
        <div>
          <Typography className={classes.title} color={'primary'} variant={'h4'}>
            OptionRef
          </Typography>
          <Typography variant={'body1'}>
            Forgot Password
          </Typography>
        </div>
        <Formik
          initialValues={{ email: '' }}
          validationSchema={ForgotSchema}
          onSubmit={forgotPass}>
          {
            (renderProps) => {
              // eslint-disable-next-line react/prop-types
              const {values, handleSubmit, touched, errors } = renderProps;
              return (
                <div className={classes.form}>
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Field
                          fullWidth
                          error={errors.email && touched.email}
                          helperText={errors.email && touched.email ? errors.email : ''}
                          className={classes.input}
                          name={'email'}
                          value={values.email}
                          component={TextField}
                          label={"Email"}
                          InputProps={{ fullWidth: true }} />
                      </Grid>
                      <Grid item xs={12}>
                        <div className={classes.actions}>
                          <Button
                            onClick={goBackToLogin}
                            variant={'text'}
                            color={'textSecondary'}>
                            Back To Login
                          </Button>
                          <Button
                            disabled={!valuesFilled(values) || Object.keys(errors).length > 0}
                            type={'submit'}
                            className={classes.loginBtn}
                            variant={'contained'}
                            color={'primary'}>
                            Request Password Reset
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </form>
                </div>
              )
            }
          }
        </Formik>
      </Card>
      <div className={classes.spacer} />
    </div>
  );
};

ForgotPassword.propTypes = {
  forgotPassword: PropTypes.func.isRequired,
};

export default ForgotPassword;
