import Record from "../Record";
import {makeObservable, runInAction} from "mobx";
import mapValues from "lodash/mapValues";
import makeQuery from "../../api/makeQuery";

class Stock extends Record {
  static INITIAL_FIELDS = {
    stockId: '',
    symbol: '',
    exchange: '',
    notOption: null,
    lastPrice: 0,
    priceUpdatedAt: '',
    callDates: [],
    earningsDate: '',
    earningsUpdatedAt: '',
    expirationUpdatedAt: '',
    updatedAt: '',
    createdAt: ''
  };

  constructor(account, initialInfo) {
    super(initialInfo);
    makeObservable(this, {
      ...mapValues(this.constructor.INITIAL_FIELDS, () => true),
    });
    this.account = account;
  }

  async subscribe() {
    if (this.isSubscribed) return;
    const accountInfo = await makeQuery('@postStockSubscription', {
      userId: this.account?.userId,
      stockId: this.stockId,
    });

    runInAction(() => {
      this.account?.merge(accountInfo);
    });
  }

  async unsubscribe() {
    if (!this.isSubscribed || !this.subscription) return;
    const accountInfo = await makeQuery('@deleteStockSubscription', {
      userId: this.account?.userId,
      subscriptionId: this.subscription.id,
    });

    runInAction(() => {
      this.account?.merge(accountInfo);
    });
  }

  get isSubscribed() {
    return Boolean(this.account.subscribedStocks.getById(this.stockId));
  }

  get subscription() {
    return this.account?.stockSubs?.find(sub => sub.stockId === this.stockId);
  }

  destroy() {
    super.destroy();
  }
}

export default Stock;
