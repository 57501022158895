const TAG = 'HISTORIES';

const PUT_HISTORY = `${TAG}/putHistory`;
export const putHistory = (history) => ({ type: PUT_HISTORY, history });

const CLEAR_HISTORY = `${TAG}/clearHistory`;
export const clearHistory = (historyId) => ({ type: CLEAR_HISTORY, historyId });

const PUT_HISTORIES = `${TAG}/putHistories`;
export const putHistories = (histories) => ({ type: PUT_HISTORIES, histories });

const CLEAR_HISTORIES = `${TAG}/clearHistories`;
export const clearHistories = () => ({ type: CLEAR_HISTORIES });


export const HistoryActionTypes = {
  PUT_HISTORY,
  PUT_HISTORIES,
  CLEAR_HISTORY,
  CLEAR_HISTORIES
};
