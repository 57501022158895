import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import layoutStyles from "./layoutStyles";
import NavBar from "../components/NavBar";

const useStyles = makeStyles(theme => ({
  root: {
    ...layoutStyles.root,
  },
  content: {
    ...layoutStyles.content
  },
  toolbar: theme.mixins.toolbar,
}));

const AuthLayout = props => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <NavBar title={'OptionRef'} />
      <div className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </div>
    </div>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.node,
}

export default AuthLayout;
